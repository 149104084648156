import React from 'react';
import {css} from 'linaria';
import {styled} from 'linaria/react';
import {SearchField} from '@jetshop/ui/Search';
import AutocompleteQuery from './AutocompleteQuery.gql';
import {theme} from '../../Theme';

const PosedSearchBarContainer = styled('div')`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;

  //svg {
  //  position: absolute;
  //  right: 8px;
  //  top: 0;
  //  bottom: 0;
  //  margin: auto;
  //  use {
  //    fill: #878787;
  //  }
  //}
`;

const Positioner = styled('div')`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  background-color: rgba(31, 31, 31, 0.85);
`;

const InnerSearchBar = styled('div')`
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: 120rem;
`;

const searchFieldStyles = css`
  &[data-flight-searchfield] > * {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 51px;
    width: 100%;
    padding: 0 2rem;

    input {
      font-size: 16px;
      border: 0;
      background: transparent;
      height: auto;
      width: 200px;
      padding: 3px 0;
      border-bottom: 1px solid ${theme.colors.white};
      color: ${theme.colors.white};
      outline: none;
    }
  }

  [data-flight-searchfield-cancel] {
    height: 100%;
    min-width: 48px;
    width: fit-content;
    display: none;
  }

  [data-flight-searchfield-flyout] {
    text-align: left;
    top: 50px;
    left: initial;
    width: 400px;
    border-color: ${theme.colors.black};

    ${theme.below.md} {
      top: calc(100% + ((64px - 100%) / 2));
      width: calc(100% + 4rem + 16px);
      left: -8px;
      border-left: none;
      border-right: none;
    }

    > * {
      padding: 0.5rem;
    }

    h2 {
      //color: #999999;
      text-transform: uppercase;
      font-size: 18px;
      padding: 0.5rem;
    }

    li {
      //border-top: 1px solid #e8e8e8;
    }

    a {
      color: black;
      padding: 0.375rem 0.5rem;
      display: block;
      font-size: 16px;
      line-height: 1.3;
      
      &:hover {
        
          text-decoration: underline;
      }
      
    }
  }
`;

const SearchBar = ({searchOpen, setSearchOpen}) => {


  return searchOpen ? (
    <PosedSearchBarContainer key={'searchBarPosed'}>
      <Positioner>
        <InnerSearchBar>
          <SearchField
            autocompleteQuery={AutocompleteQuery}
            onCancel={() => setSearchOpen(false)}
            className={searchFieldStyles}
            onSubmit={() => setSearchOpen(false)}
          />
        </InnerSearchBar>
      </Positioner>
    </PosedSearchBarContainer>
  ) : null
};

export {SearchBar};
