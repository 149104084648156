
import { styled } from 'linaria/react';
import React from 'react';
import { theme } from '../Theme';

const PaginationContainer = styled('div')`
  display: flex;
  padding: 2rem 0;
  justify-content: center;
  align-items: center;

  ${theme.above.sm} {
    //padding-top: 2rem;
  }
`;

const NavigationArrow = styled('span')`
  font-size: 24px;
  font-weight: bold;
  padding: 0.5rem 0.75rem;
  cursor: pointer;
  position: relative;

  &:hover {
    text-decoration: underline;
  }

  &.inactive {
    visibility: hidden;
    z-index: -10;
  }
`;

const PageList = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PageNumber = styled('span')`
  padding: 0.75rem;
  margin: 0.25rem;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  &.active {
    font-weight: 400;
    cursor: default;
    font-size: 14px;

    &:hover {
      text-decoration: none;
    }
  }
`;

const ArticlePagination = ({
  articlesPerPage,
  articleQty,
  currentPage,
  setCurrentPage
}) => {
  const totalPageQty = Math.ceil(articleQty / articlesPerPage);
  let pageArray = [];

  const setPage = pageNumber => {
    if (
      pageNumber !== currentPage &&
      pageNumber !== 0 &&
      pageNumber <= totalPageQty
    ) {
      setCurrentPage(pageNumber);
    }
  };

  for (let i = 1; i <= totalPageQty; i++) {
    if (
      i === 1 ||
      i === currentPage ||
      i === currentPage - 1 ||
      i === currentPage - 2 ||
      i === currentPage + 1 ||
      i === currentPage + 2
    ) {
      pageArray.push(i);
    } else if (i === totalPageQty) {
      pageArray.push(i);
    } else if (pageArray[pageArray.length - 1] !== 0) {
      pageArray.push(0);
    }
  }

  return (
    <>
      {totalPageQty > 1 && (
        <PaginationContainer>
          <NavigationArrow className={currentPage === 1 ? 'inactive' : ''}>
            ‹
          </NavigationArrow>
          <PageList>
            {pageArray.map((pageNumber, index) => {
              return (
                <PageNumber
                  key={'page-' + index}
                  className={pageNumber === currentPage ? 'active' : 'inactive'}
                  onClick={() => {
                    setPage(pageNumber);
                  }}
                >
                  {pageNumber ? pageNumber : '...'}
                </PageNumber>
              );
            })}
          </PageList>
          <NavigationArrow
            className={currentPage === totalPageQty ? 'inactive' : ''}
          >
            ›
          </NavigationArrow>
        </PaginationContainer>
      )}
    </>
  );
};
export default ArticlePagination;
