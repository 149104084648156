import React from 'react';
import { styled } from 'linaria/react';

import { theme } from '../../Theme';

const InfoItemWrapper = styled('div')`
  width: calc(50% - 2rem);
  padding-top: 1.5rem;

  ${theme.below.md} {
    width: 100%;
  }
  ${theme.below.md} {
  }
`;
const Container = styled('div')``;
const Title = styled('h3')`
  font-size: 1rem;
  font-weight: ${theme.fontWeights.bold};
  line-height: 1.6;
  font-family: ${theme.fonts.primary};

  ${theme.below.lg} {
    font-size: 14px;
  }
`;

const Description = styled('p')`
  font-size: 1rem;
  line-height: 1.6;

  ${theme.below.lg} {
    font-size: 14px;
  }
`;

const InfoItem = ({ headingText, bodyText }) => {
  headingText = headingText?.value;
  bodyText = bodyText?.value;

  return (
    <InfoItemWrapper>
      <Container>
        {headingText && <Title>{headingText}</Title>}
        {bodyText && <Description>{bodyText}</Description>}
      </Container>
    </InfoItemWrapper>
  );
};

export default InfoItem;
