import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../Theme';

const Wrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  border: 0;
  border-radius: 50%;
  line-height: 1.1;
  background-color: ${theme.colors.white};
  overflow: hidden;
  color: ${theme.colors.black};
  border: 2px solid ${theme.colors.black};
  font-family: ${theme.fonts.secondary};
  font-size: 14px;
  padding-top: 1px;

`;

const Badge = ({ text }) => {
  return (
    <Wrapper className="badge">{text}</Wrapper>
  );
};

export default Badge;
