import CategoryLink from '@jetshop/ui/CategoryLink';
import React, { useState } from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../../Theme';
import UIMaxWidth from '../../MaxWidth';
import SubMenuSection from './SubMenuSection';
import AnimateHeight from 'react-animate-height';

const borderColor = '#e8e8e8';

// Limit the menu to our global MaxWidth
const MaxWidth = styled(UIMaxWidth)`
  width: 440px;
  padding: 0 0 3rem;
  //flex-direction: column;
  //justify-content: flex-start;
  display: block;

  &::after {
    content: '';
    display: block;
    clear: both;
  }

  a {
  }
`;

// Set the submenu a minimum of 50% of the browser view, but fit the content
// so it doesn't always extend to 100%.
const ContentFit = styled('div')`
  width: fit-content;
  margin: 0 auto;
  min-width: 50vw;
`;

// We need to put the border here (inside the Wrapper), because if we put it directly
// on the Wrapper it will still be shown when the Wrapper's height is 0
const InnerWrapper = styled('div')`
    //border: 1px solid ${borderColor};
  //border-width: 1px 0 1px 0;
  width: 100%;
`;

const Wrapper = styled('section')`
  display: flex;
  justify-content: center;
  position: absolute;
  right: 0;
  left: 0;
  //background: rgba(31, 31, 31, 0.9);
  overflow: hidden;
  z-index: 10;
  will-change: height;
  transition: height 0.3s ease-in-out, opacity 0.3s;
  background: linear-gradient(
    to bottom,
    rgba(31, 31, 31, 1),
    rgba(31, 31, 31, 0.9) 100%
  );
`;

const NoThirdTierItems = styled('div')`
  h2 {
    font-size: inherit;
  }
`;

const SubMenuContent = styled('div')`
  position: relative;
  min-width: 140px;
  height: 250px;
  float: left;

  h2 {
    margin: 0;
    padding: 0;
  }

  a {
    padding: 0 0.5rem;
    text-decoration: none;

    &:hover {
      span {
        border-color: ${theme.colors.white};
      }
    }

    span {
      display: inline-block;
      border-bottom: 1px solid transparent;
      padding: 4px 0;
      font-weight: ${theme.fontWeights.medium};
      font-size: 1rem;
      color: ${theme.colors.white};
      font-family: ${theme.fonts.primary};
      letter-spacing: 0;
    }
  }

  div.lv2 {
    + div.lv2 {
      margin-top: 17px;

      &.no-lv3 {
        //margin-top: 11px;
      }
    }

    &.no-lv3 {
      a span {
        font-weight: ${theme.fontWeights.regular};
      }
    }

    &.show-subcategories {
      > h2 a span {
        border-color: ${theme.colors.white};
      }

      div.lv3 {
        display: block;
      }
    }
  }

  div.lv3 {
    position: absolute;
    top: 7px;
    right: 0;
    bottom: -2px;
    display: none;
    border-left: 1px solid ${theme.colors.white};

    ul {
      position: absolute;
      top: -5px;
      left: 9px;
      bottom: 0;
      margin: 0;
      display: block;
      animation: fade-in-animation 0.2s normal;
    }

    li {
      max-width: 10rem;
      padding: 0;
      margin: 0;
      white-space: nowrap;

      + li {
        margin-top: 12px;
      }

      a {
        span {
          font-weight: ${theme.fontWeights.regular};
        }

        :hover,
        &.active {
        }
      }
    }

    a {
      :hover {
      }
    }
  }

  &.no-lv3 {
    display: flex;
    flex-flow: column;
    flex-wrap: wrap;
    padding-top: 4px;

    div.lv2 {
      margin: 0 2rem 12px 0 !important;
    }
  }

  @keyframes fade-in-animation {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const SubMenuWrapper = ({
  pose,
  activeCategory,
  closeNav,
  debouncedClose,
  client,
  data
}) => {
  const [visibleLv3SubCat, setVisibleLv3SubCat] = useState(0);
  let lv2Cats = activeCategory ? [...activeCategory.subcategories] : [];
  const blogCategoryId = theme.settings.blogCategoryId;
  const faqCategoryId = theme.settings.faqCategoryId;

  // CHECK IF IS COMPLETELY FREE OF LV3
  let hasLv3Cats = false;

  if (activeCategory) {
    lv2Cats.forEach(lv2Cat => {
      if (
        lv2Cat.hasSubcategories &&
        lv2Cat.id !== blogCategoryId &&
        lv2Cat.id !== faqCategoryId
      ) {
        hasLv3Cats = true;
      }
    });
  }

  return (
    <Wrapper className="sub-menu-wrapper">
      <InnerWrapper onMouseEnter={() => debouncedClose?.cancel()}>
        <ContentFit>
          <AnimateHeight
            height={pose === 'open' ? 'auto' : 0}
            duration={300}
            animateOpacity={true}
          >
            <MaxWidth>
              <SubMenuContent className={hasLv3Cats ? 'has-lv3' : 'no-lv3'}>
                {activeCategory &&
                  lv2Cats.map((lv2Cat, index) => {
                    let shouldRenderLv3 = true;
                    if (
                      lv2Cat.id === blogCategoryId ||
                      lv2Cat.id === faqCategoryId
                    ) {
                      shouldRenderLv3 = false;
                    }
                    return (
                      <React.Fragment key={lv2Cat.id}>
                        {hasLv3Cats && lv2Cat.id ? (
                          <SubMenuSection
                            lv2Cat={lv2Cat}
                            onSelect={closeNav}
                            key={lv2Cat.id}
                            isActive={visibleLv3SubCat === index}
                            lv2Index={index}
                            setVisibleLv3SubCat={setVisibleLv3SubCat}
                            shouldRenderLv3={shouldRenderLv3}
                          />
                        ) : (
                          <NoThirdTierItems
                            className={'lv2 no-lv3'}
                            key={lv2Cat.id}
                          >
                            <h2>
                              <CategoryLink
                                category={lv2Cat}
                                onClick={closeNav}
                              >
                                <span>{lv2Cat.name}</span>
                              </CategoryLink>
                            </h2>
                          </NoThirdTierItems>
                        )}
                      </React.Fragment>
                    );
                  })}
              </SubMenuContent>
            </MaxWidth>
          </AnimateHeight>
        </ContentFit>
      </InnerWrapper>
    </Wrapper>
  );
};

export default SubMenuWrapper;
