import { styled } from 'linaria/react';
import React, { useContext } from 'react';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import qs from 'qs';
import { Link } from 'react-router-dom';
import { useSpacer } from '../useSpacer';

const EmbedContainer = styled.section`
  position: relative;
  width: 100%;

  section& {
    margin-bottom: 3rem;
  }
  
  .video-wrapper {
    padding-bottom: ${({ aspect }) => aspect};
  }

  video,
  iframe {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    height: 100%;
  }
`;

function getAspectRatio(aspect) {
  switch (aspect) {
    case '16/9':
      return '56.25%';
    case '4/3':
      return '75%';
    case '3/4':
      return '133.33%';
    default:
      return '56.25%';
  }
}

const base = {
  Youtube: ({ id, autoplay, controls, loop = false }) => {
    const params = {
      mute: autoplay ? 1 : 0,
      autoplay: autoplay ? 1 : 0,
      loop: loop ? 1 : 0,
      controls: controls ? 1 : 0,
      playsinline: 1,
    };

    return `https://www.youtube.com/embed/${id}?${qs.stringify(params)}`;
  },
  Vimeo: ({ id, autoplay, controls }) => {
    const params = {
      muted: autoplay ? 1 : 0,
      autoplay: autoplay ? 1 : 0,
      controls: controls ? 1 : 0,
      playsinline: 1,
    };

    return `https://player.vimeo.com/video/${id}?${qs.stringify(params)}`;
  },
};

export const Video = (props) => {
  const type = props.videoType.value;
  const aspect = getAspectRatio(props.aspect?.value);
  const link = props?.link?.value;
  const element = useSpacer(props);

  return (
    <EmbedContainer aspect={aspect} as={element}>
      {link ? (
        <div className="video-wrapper">
          <Link to={link}>
            {type === 'Internal' ? (
              <InternalVideo {...props} />
            ) : (
              <ExternalVideo {...props} />
            )}
          </Link>
        </div>
      ) : (
        <div className="video-wrapper">
          {type === 'Internal' ? (
            <InternalVideo {...props} />
          ) : (
            <ExternalVideo {...props} />
          )}
        </div>
      )}
    </EmbedContainer>
  );
};

const InternalVideo = ({ videoId, autoplay, controls, preloadimage }) => {
  const { selectedChannel } = useContext(ChannelContext);

  // Apparently, for certain attributes, React is intelligent enough to omit the attribute if the value you pass to it is not truthy
  return (
    <video
      width="100%"
      muted={autoplay.value}
      autoPlay={autoplay.value}
      controls={controls.value}
      loop
      playsInline
      poster={preloadimage.value}
    >
      <source
        src={`${selectedChannel.url}/pub_docs/files/video/${videoId?.value}`}
        type="video/mp4"
      />
    </video>
  );
};

export const ExternalVideo = ({ videoType, videoId, controls, autoplay }) => {
  const type = videoType.value.value;

  const src = base?.[type]({
    id: videoId.value,
    autoplay: autoplay.value,
    controls: controls.value,
  });

  return (
    <iframe
      src={src}
      frameBorder="0"
      title="Embedded video"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; loop;"
      allowFullScreen
    ></iframe>
  );
};
