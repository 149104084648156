import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../Theme';
import Image from '@jetshop/ui/Image/Image';
import getContentValue from "../getContentValue";

const ImageWrapper = styled('figure')`
  margin: 1.25rem 0;

  ${theme.above.sm} {
    &.floated {
      width: 50%;
      margin-top: 0.25rem;

      + p {
        margin-top: 0;
      }
    }

    &.float-left {
      float: left;
      margin-right: 1.25rem;
    }

    &.float-right {
      float: right;
      margin-left: 1.25rem;
    }
  }
`;

const Caption = styled('figcaption')`
  padding: 0.75rem 0 0;
  margin: 0;
  font-weight: ${theme.fontWeights.light};
  font-size: 14px;

  ${theme.below.sm} {
    font-size: 13px;
  }
`;

const SingleImage = ({ image, caption, aspect, layout }) => {
  image = getContentValue(image);
  caption = getContentValue(caption);
  aspect = getContentValue(aspect) ? getContentValue(aspect) : '1:1';
  layout = getContentValue(layout) ? getContentValue(layout) : '';

  let layoutClassName = 'full-width';

  if (layout.match(/left/i)) {
    layoutClassName = 'floated float-left';
  } else if (layout.match(/right/i)) {
    layoutClassName = 'floated float-right';
  }

  return (
    <ImageWrapper className={layoutClassName}>
      <Image src={image} sizes={[1, 1, 0.5, 0.5]} aspect={aspect} crop={true} />
      {caption && <Caption>{caption}</Caption>}
    </ImageWrapper>
  );
};

export default SingleImage;
