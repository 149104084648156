const showroomData = {
  choice: {
    key: 'kitchen-environment',
    choiceHeading_sv_SE: 'Köksmiljö',
    choiceHeading_nb_NO: 'Kjøkkenmiljø',
    choiceHeading_da_DK: 'Køkkenmiljø',
    choiceHeading_fi_FI: 'Keittiön ympäristö',
    choiceText: '',
    choiceImage: '',
    choiceHelpText: '',
    options: [
      {
        optionHeading: 'Shaker',
        optionText: '',
        optionImage: '',
        includedProducts: [
          {
            productName: 'Shaker',
            sku: 'k3'
          },
          {
            productName_sv_SE: 'Modernt',
            productName_nb_NO: 'Moderne',
            productName_da_DK: 'Moderne',
            productName_fi_FI: 'Moderni',
            sku: 'k1'
          },
          {
            productName_sv_SE: 'Natur',
            productName_nb_NO: 'Natur',
            productName_da_DK: 'Natur',
            productName_fi_FI: 'Luonto',
            sku: 'k2'
          }
        ],
        singleChoiceHeading: '',
        choice: {
          key: 'kitchen-color',
          choiceHeading_sv_SE: 'Köksfärg',
          choiceHeading_nb_NO: 'Kjøkken maling',
          choiceHeading_da_DK: 'Køkken maling',
          choiceHeading_fi_FI: 'Keittiön maali',
          choiceText: '',
          choiceImage: '',
          choiceHelpText: '',
          isRender: true,
          isColor: true,
          options: [
            {
              optionHeading: 'Köksfärg',
              optionText: '',
              optionImage: '',
              singleChoiceHeading: 'Köksfärg',
              singleChoiceProducts: [
                {
                  productName_sv_SE: 'Ljust',
                  productName_nb_NO: 'Lys',
                  productName_da_DK: 'Lys',
                  productName_fi_FI: 'Kevyt',
                  sku: 's1'
                },
                {
                  productName_sv_SE: 'Mörkt',
                  productName_nb_NO: 'Mørk',
                  productName_da_DK: 'Mørk',
                  productName_fi_FI: 'Tumma',
                  sku: 's2'
                }
              ],
              choice: {
                key: 'fan-type',
                choiceHeading_sv_SE: 'Fläkttyp',
                choiceHeading_nb_NO: 'Fan type',
                choiceHeading_da_DK: 'Fan type',
                choiceHeading_fi_FI: 'Tuulettimen tyyppi',
                choiceText: '',
                choiceImage: '',
                choiceHelpText: '',
                isRender: true,
                isColor: true,
                options: [
                  {
                    optionHeading: 'Frihängande köksfläkt',
                    optionText: '',
                    optionImage: '',
                    singleChoiceHeading: 'Frihängande köksfläkt',
                    singleChoiceProducts: [
                      {
                        productName_sv_SE: 'Frihängande köksfläkt',
                        productName_nb_NO: 'Fritthengende kjøkkenhette',
                        productName_da_DK: 'Frithængende køkkenemhætte',
                        productName_fi_FI: 'Vapaasti roikkuva liesituuletin',
                        sku: 'Kitchen_f'
                      }
                    ],
                    choice: {
                      key: 'fan-model',
                      choiceHeading_sv_SE: 'Fläktmodell',
                      choiceHeading_nb_NO: 'Viftemodell',
                      choiceHeading_da_DK: 'Fan model',
                      choiceHeading_fi_FI: 'Tuulettimen malli',
                      choiceText: '',
                      choiceImage: '',
                      choiceHelpText: '',
                      isRender: true,
                      isColor: true,
                      options: [
                        {
                          optionHeading: 'Fläktmodell',
                          optionText: '',
                          optionImage: '',
                          singleChoiceHeading: 'Fläktmodell',
                          singleChoiceProducts: []
                        }
                      ]
                    }
                  },
                  {
                    optionHeading: 'Vägghängd köksfläkt',
                    optionText: '',
                    optionImage: '',
                    singleChoiceHeading: 'Vägghängd köksfläkt',
                    singleChoiceProducts: [
                      {
                        productName_sv_SE: 'Vägghängd köksfläkt',
                        productName_nb_NO: 'Vegghengt kjøkkenhette',
                        productName_da_DK: 'Vægmonteret køkkenemhætte',
                        productName_fi_FI:
                          'Seinään kiinnitettävä liesituuletin',
                        sku: 'Kitchen_w'
                      }
                    ],
                    choice: {
                      key: 'fan-model',
                      choiceHeading_sv_SE: 'Fläktmodell',
                      choiceHeading_nb_NO: 'Viftemodell',
                      choiceHeading_da_DK: 'Fan model',
                      choiceHeading_fi_FI: 'Tuulettimen malli',
                      choiceText: '',
                      choiceImage: '',
                      choiceHelpText: '',
                      isRender: true,
                      isColor: true,
                      options: [
                        {
                          optionHeading: 'Fläktmodell',
                          optionText: '',
                          optionImage: '',
                          singleChoiceHeading: 'Fläktmodell',
                          singleChoiceProducts: []
                        }
                      ]
                    }
                  }
                ]
              }
            }
          ]
        }
      }
    ]
  }
};

export const getShoowroomData = showroomSettings => {
  if (!showroomSettings) {
    return {};
  }
  let wallMounted = [];
  let freeMounted = [];
  for (const showroomItem of showroomSettings?.children) {
    const name = showroomItem.properties.find(p => p.name == 'name')?.value
      ?.value;
    const sku = showroomItem.properties.find(p => p.name == 'sku')?.value
      ?.value;
    const type = showroomItem.properties.find(p => p.name == 'type')?.value
      ?.value;
    if (type == 'Vägghängd köksfläkt') {
      wallMounted.push({ productName: name, sku: sku });
    } else {
      freeMounted.push({ productName: name, sku: sku });
    }
  }
  traverseAndUpdateModels(wallMounted, freeMounted, showroomData);
  return showroomData.choice;
};

function traverseAndUpdateModels(wallMounted, freeMounted, obj) {
  for (const key in obj) {
    if (obj?.optionHeading == 'Vägghängd köksfläkt') {
      // console.log(obj)
      obj.choice.options[0].singleChoiceProducts = wallMounted;
    }
    if (obj?.optionHeading == 'Frihängande köksfläkt') {
      // console.log(obj)
      obj.choice.options[0].singleChoiceProducts = freeMounted;
    }
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      traverseAndUpdateModels(wallMounted, freeMounted, obj[key]); // Recursively call the function for nested objects
    }
  }
}
