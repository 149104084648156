import React, { useContext} from 'react';
import ChannelContext from '@jetshop/core/components/ChannelContext/index';
import { styled } from 'linaria/react';

let dataLayer = [];
if (typeof window !== 'undefined') {
  window.dataLayer = window.dataLayer || [];
  dataLayer = window.dataLayer || dataLayer;
}

const isPageLoadDuplicateEntry = dataObj => {
  let isDuplicate = false;
  const lastEntries = [...dataLayer.slice(dataLayer.length - 100).reverse()];
  for (const entry of lastEntries) {
    if (entry.event && entry.event === 'pageInfo') {
      if (entry.pagePath === dataObj.pagePath) {
        isDuplicate = true;
      }
      break;
    }
  }
  return isDuplicate;
};

const isProdDetailDuplicateEntry = dataObj => {
  let isDuplicate = false;
  const lastEntries = [...dataLayer.slice(dataLayer.length - 4).reverse()];
  for (const entry of lastEntries) {
    if (entry.event && entry.event === 'ecom-product-detail') {
      if (
        entry.ecommerce.detail.products[0].id ===
        dataObj.ecommerce.detail.products[0].id
      ) {
        isDuplicate = true;
      }
      break;
    }
  }
  return isDuplicate;
};

const isProdImpressionDuplicateEntry = dataObj => {
  let isDuplicate = false;
  const lastEntries = [...dataLayer.slice(dataLayer.length - 5).reverse()];
  for (const entry of lastEntries) {
    if (entry.event && entry.event === 'ecom-product-impression') {
      if (
        entry.ecommerce.impressions[0].category ===
        dataObj.ecommerce.impressions[0].category
      ) {
        isDuplicate = true;
      }
      break;
    }
  }
  return isDuplicate;
};

export const TrackPageLoad = ({pageType, route}) => {
  // console.log(selectedChannel, " - selectedChannel");
  const { selectedChannel } = useContext(ChannelContext);
  const docTitle = typeof document !== 'undefined' ? document.title : '';
  const pathName = typeof window !== 'undefined' ? window.location.pathname : '';
  const environment =
    pathName && window.location.hostname.match(/dev|local/i) ? 'stage' : 'production';
  let businessArea = '';

  if(route) {
    businessArea = route.id.split('/')[0];
    businessArea = businessArea.charAt(0).toUpperCase() + businessArea.substr(1);
  }

  const dataObj = {
    event: 'pageInfo',
    pageType: pageType,
    pageTitle: docTitle.replace(' - Fjäråskupan Webbshop', ''),
    pagePath: pathName,
    environment: environment,
    loginStatus: '',
    userId: '',
    country: selectedChannel.country.code,
    businessArea: businessArea
  };

  if (!isPageLoadDuplicateEntry(dataObj)) {
    dataLayer.push(dataObj);
    // console.log(dataObj.pagePath, " - dataObj.pagePath");
  }
  return null;
};

export const TrackProductPageDetails = ({product}) => {
  const { selectedChannel } = useContext(ChannelContext);
  if (product && product.articleNumber) {
    const dataObj = {
      event: 'ecom-product-detail',
      ecommerce: {
        currencyCode: selectedChannel.currency.format.code,
        market: selectedChannel.country.code,
        detail: {
          actionField: {
            action: 'detail'
          },
          products: [
            {
              id: product.articleNumber,
              price: product.price.incVat
            }
          ]
        }
      }
    };

    if (!isProdDetailDuplicateEntry(dataObj)) {
      dataLayer.push(dataObj);
      // console.log(dataObj.ecommerce.detail.products[0].id, " - dataObj.ecommerce.detail.products[0].id");
    }
  }
  return null;
};

const ClickTrackWrapper = styled('div')`
  width: 100%;
  margin: 0;
  padding: 0;
  border: 0;
`;

export const ProductGridClickTracker = props => {
  const { selectedChannel } = useContext(ChannelContext);
  const product = props.product;

  const clickAction = () => {
    const dataObj = {
      event: 'ecom-product-click',
      ecommerce: {
        currencyCode: selectedChannel.currency.format.code,
        market: selectedChannel.country.code,
        action: {
          name: product.name,
          id: product.articleNumber,
          price: product.price.incVat,
          category: props.categoryName
        }
      }
    };
    dataLayer.push(dataObj);
  };

  return (
    <ClickTrackWrapper onClick={clickAction}>
      {props.children}
    </ClickTrackWrapper>
  );
};

export const TrackProductImpressions = ({products, category}) => {
  const { selectedChannel } = useContext(ChannelContext);

  if (products && products.length && category && category.name) {
    let impressions = [];
    products.forEach((product, index) => {
      impressions.push({
        name: product.name,
        id: product.articleNumber,
        price: product.price.incVat,
        category: category.name
      });
    });

    const dataObj = {
      event: 'ecom-product-impression',
      ecommerce: {
        currencyCode: selectedChannel.currency.format.code,
        market: selectedChannel.country.code,
        impressions: impressions
      }
    };

    if (!isProdImpressionDuplicateEntry(dataObj)) {
      dataLayer.push(dataObj);
    }
  }
  return null;
};

export const AddToCartTracker = (products, selectedChannel) => {
  let addedProducts = [];
  products.forEach((product, index) => {
    const qty = product.qty || product.quantity || 1;
    addedProducts.push({
      name: product.name,
      id: product.articleNumber,
      price: product.price.incVat,
      quantity: qty
    });
  });

  const dataObj = {
    event: 'ecom-add-to-cart',
    ecommerce: {
      currencyCode: selectedChannel.currency.format.code,
      market: selectedChannel.country.code,
      add: {
        products: addedProducts
      }
    }
  };
  dataLayer.push(dataObj);
  // console.log(dataObj, ' - dataObj');
};

export const IncreaseClickTracker = props => {
  const { selectedChannel } = useContext(ChannelContext);

  const clickAction = () => {
    AddToCartTracker([props.item.product], selectedChannel);
  };

  return <div onClick={clickAction}>{props.children}</div>;
};

export const AddConfigurationClickTracker = props => {
  const { selectedChannel } = useContext(ChannelContext);
  const clickAction = () => {
    let products = [];
    props.items.forEach((item, index) => {
      let product = { ...item.product };
      product.qty = item.quantity;
      products.push(product);
    });
    RemoveFromCartTracker(products, selectedChannel);
  };
  return <div onClick={clickAction}>{props.children}</div>;
};

export const RemoveFromCartTracker = (products, selectedChannel) => {
  let removedProducts = [];
  products.forEach((product, index) => {
    const qty = product.qty || product.quantity || 1;
    removedProducts.push({
      name: product.name,
      id: product.articleNumber,
      price: product.price.incVat,
      quantity: qty
    });
  });

  const dataObj = {
    event: 'ecom-remove-from-cart',
    ecommerce: {
      currencyCode: selectedChannel.currency.format.code,
      market: selectedChannel.country.code,
      remove: {
        products: removedProducts
      }
    }
  };
  dataLayer.push(dataObj);
  // console.log(dataObj, ' - dataObj');
};

export const DecreaseClickTracker = props => {
  const { selectedChannel } = useContext(ChannelContext);
  const clickAction = () => {
    RemoveFromCartTracker([props.item.product], selectedChannel);
  };
  return <div onClick={clickAction}>{props.children}</div>;
};

export const RemoveClickTracker = props => {
  const { selectedChannel } = useContext(ChannelContext);
  const clickAction = () => {
    let removedProduct = { ...props.item.product };
    removedProduct.qty = props.item.quantity;
    RemoveFromCartTracker([removedProduct], selectedChannel);
  };
  return <div onClick={clickAction}>{props.children}</div>;
};

export const RemoveConfigurationClickTracker = props => {
  const { selectedChannel } = useContext(ChannelContext);
  const clickAction = () => {
    let products = [];
    props.items.forEach((item, index) => {
      let product = { ...item.product };
      product.qty = item.quantity;
      products.push(product);
    });
    RemoveFromCartTracker(products, selectedChannel);
  };
  return <div onClick={clickAction}>{props.children}</div>;
};
