import React, { useState, useEffect } from 'react';
import AnimateHeight from 'react-animate-height';
import { styled } from 'linaria/react';
import { ReactComponent as Caret } from '@jetshop/ui/svg/Carrot.svg';

const Accordion = styled('div')`
  padding: 1rem 0.5rem 1rem 0;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    svg {
      height: 15px;
      width: 28px;
      transition: transform 0.2s ease-out;
    }
  }

  .rotate-arrow {
    transform: rotate(180deg) scale(100%);
  }
`;

const ShowroomAccordion = ({ title, children, isOpen, onClick, index }) => {
  const [height, setHeight] = useState(isOpen ? 'auto' : 0);

  useEffect(() => {
    setHeight(isOpen ? 'auto' : 0);
  }, [isOpen]);

  return (
    <Accordion>
      <div className="header" onClick={() => onClick(index)}>
        <h2>{title}</h2>
        <Caret className={`${height !== 0 && 'rotate-arrow'}`}></Caret>
      </div>
      <AnimateHeight id="accordion-panel" duration={500} height={height}>
        {children}
      </AnimateHeight>
    </Accordion>
  );
};

export default ShowroomAccordion;
