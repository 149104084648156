import React, {useContext} from 'react';
import { Link } from 'react-router-dom';
import { styled } from 'linaria/react';
import FkLogo from '../../../svg/FkLogo.svg';
import { theme } from '../../Theme';
import ChannelContext from "@jetshop/core/components/ChannelContext";

const StoreName = styled('h2')`
  font-size: 30px;
  font-weight: bold;
  line-height: normal;
  text-align: center;
  text-decoration: none;
  color: inherit;
  width: 200px;

  ${theme.below.md} {
    width: auto;
  }

  a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: inherit;
  }

  img {
    width: 188px;
    //height: 44px;

    ${theme.below.md} {
      width: 128px;
    }
  }
`;

const Logo = () => {
  const {selectedChannel} = useContext(ChannelContext);
  const channelName = selectedChannel.name;

  return (

  <StoreName>
    <Link to="/">
      <img alt="Fjäråskupan logo" src={channelName === 'B2B' ? '/logo-b2b.png' : FkLogo} />
    </Link>
  </StoreName>
)};

export { Logo };
