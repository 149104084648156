import React from 'react';
import { styled } from 'linaria/react';
import t from '@jetshop/intl';
import { theme } from '../../Theme';
import getContentValue from "../getContentValue";

const BlogInfoWrapper = styled('div')`
  ${theme.below.md} {
  }
`;
const DateContainer = styled('h2')`
  width: 100%;
  padding: 0 0 1rem;
  font-size: 18px;

  ${theme.below.md} {
  %;
    padding: 0rem 0 1rem;
    font-size: 16px;
  }

  ${theme.below.sm} {
  %;
    padding: 0rem 0 0.5rem;
  }

`;

export const DateText = ({ year, month, day }) => {

  year = getContentValue(year);
  month = getContentValue(month);
  day = getContentValue(day);

  return (
    <>
      {day} <Month month={month} />, {year}
    </>
  );
};

const KeywordContainer = styled('div')`
  padding: 0 0 1rem;

  ${theme.below.xl} {
  }

  ${theme.below.md} {
  }


  ${theme.below.sm} {
  %;
    padding: 0 0 1.25rem;
  }
`;

const Month = function({ month }) {
  return (
    <>
      {month === '01' && t('January')}
      {month === '02' && t('February')}
      {month === '03' && t('March')}
      {month === '04' && t('April')}
      {month === '05' && t('May')}
      {month === '06' && t('June')}
      {month === '07' && t('July')}
      {month === '08' && t('August')}
      {month === '09' && t('September')}
      {month === '10' && t('October')}
      {month === '11' && t('November')}
      {month === '12' && t('December')}
    </>
  );
};

const BlogArticleInfo = ({ year, month, day, children }) => {
  return (
    <BlogInfoWrapper>
      <DateContainer>
        <DateText year={year} month={month} day={day} />
      </DateContainer>
      <KeywordContainer>{children}</KeywordContainer>
    </BlogInfoWrapper>
  );
};

export default BlogArticleInfo;
