import t from '@jetshop/intl';
import { styled } from 'linaria/react';
import React from 'react';
import { theme } from '../../Theme';
import Image from '@jetshop/ui/Image/Image';
import { DateText } from '../../CustomContent/Root/BlogArticleInfo';
import { TrendLink } from '../../ui/Button';
import { Link } from 'react-router-dom';

const BlurbWrapper = styled('div')`
  padding: 0 0.5rem 5.5rem;
  position: relative;
  animation: fade-in-animation 0.2s normal;

  ${theme.below.lg} {
  }

  ${theme.below.sm} {
  }

  @keyframes fade-in-animation {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const ImageContainer = styled('div')`
  padding: 66% 0 0;
  position: relative;
  background: #f7f7f7;

  ${theme.below.sm} {
  }

  > div {
    position: absolute !important;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
`;

const ArticleName = styled('h2')`
  font-size: 18px;
  margin-bottom: 16px;
  font-family: ${theme.fonts.primary};
  line-height: 1.6;
  padding: 1.5rem 0;
  margin: 0;

  ${theme.below.sm} {
    font-size: 16px;
  }
`;

const ArticleDate = styled('h3')`
  font-size: 16px;

  ${theme.below.sm} {
  }
`;

const ArticleButton = styled(TrendLink)`
  //display: inline-flex;
  margin-top: 1.5rem;
  min-width: 180px;
  position: absolute;
  left: 0;
  bottom: 1rem;

  ${theme.below.sm} {
  }
`;

const BlogArticleBlurb = ({ article }) => {
  // console.log(article, " - article");
  const articleYear = article.data?.items[0]?.properties[0].value;
  const articleMonth = article.data?.items[0]?.properties[1].value;
  const articleDay = article.data?.items[0]?.properties[2].value;

  return (
    <BlurbWrapper>
      <Link to={article.primaryRoute?.path}>
        <ImageContainer>
          {article.images.length > 0 && (
            <Image
              src={article.images}
              sizes={[1, 0.5, 0.3, 0.3]}
              fillAvailableSpace
              cover
              quality={80}
            />
          )}
        </ImageContainer>
      </Link>
      <ArticleName>{article.name}</ArticleName>
      <ArticleDate>
        <DateText year={articleYear} month={articleMonth} day={articleDay} />
      </ArticleDate>
      <ArticleButton to={article.primaryRoute?.path}>
        {t('Read more')}
      </ArticleButton>
    </BlurbWrapper>
  );
};

export default BlogArticleBlurb;
