import React from 'react';
import { styled } from 'linaria/react';
import { cx } from 'linaria';
import { Link } from 'react-router-dom';
import Spinner from './Spinner';
import t from '@jetshop/intl';
import { theme } from '../Theme';

export const baseStyles = `
  background-color: ${theme.colors.black};
  border-color: ${theme.colors.black};
  color: white;
  font-family: ${theme.fonts.secondary};
  text-transform: uppercase;
  font-size: 18px;
  border-radius: 100px;
  padding: 0.5rem 2rem;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50px;
  letter-spacing: 0.03em;
  text-align: center;
  
  span {
    margin-right: 12px;
  }
  svg {
    fill: white;
    height: 1em;
    width: 1em;
    margin-right: 1em;
  }

  ${theme.below.lg} {
  font-size: 16px;
  }


  ${theme.above.md} {
    max-width: 100%;
  }

  :disabled {
    border: 1px solid #dedede;
    opacity: 0.5;
  }

  &.secondary {
    background-color: white;
    border: 1px solid ${theme.colors.darkgrey};
    color: ${theme.colors.darkgrey};
    circle.path {
      stroke: black;
    }
  }

`;

const Button = styled('button')`
  ${baseStyles};
`;

export const TrendLink = styled(Link)`
  ${baseStyles};
`;

const StyledSpinner = styled(Spinner)`
  height: 25px;
  circle.path {
    stroke: white;
  }
`;

const ButtonWithLoading = ({
  loading,
  loadingText = t('Hold on a moment...'),
  secondary,
  ...props
}) =>
  loading ? (
    <Button
      {...props}
      className={cx(props.className, secondary && 'secondary')}
    >
      <span>{loadingText}</span>
      <StyledSpinner />
    </Button>
  ) : (
    <Button
      {...props}
      className={cx(props.className, secondary && 'secondary')}
    />
  );

export default ButtonWithLoading;
