import React, { useContext } from 'react';
import { styled } from 'linaria/react';
import FooterLinks from './FooterLinks';
import MaxWidth from '../MaxWidth';
import SocialLinks from './SocialLinks';
import { theme } from '../../Theme';
import { Logo } from '../Header/Logo';
import { useQuery } from 'react-apollo';
import categoryContentQuery from '../../CustomContent/CategoryContentQuery.gql';
import { ContentRenderer } from '@jetshop/ui/ContentRenderer';
import contentComponents from '../../CustomContent/elementMap';
import NewsletterSignup from './NewsletterSignup';
import OrderCatalog from './OrderCatalog';
import useAuth from '@jetshop/core/components/AuthContext/useAuth';
import ChannelContext from '@jetshop/core/components/ChannelContext';

const backgroundColor = theme.colors.black;

const NewsletterAndCatalogSection = styled('section')`
  display: flex;

  ${theme.below.lg} {
    flex-direction: column;

    > div {
      width: 100%;
    }
  }
`;

const PoweredByWrapper = styled('div')`
  max-width: 90rem;
  padding: 3rem 2rem;
  margin: 0 auto;
  display: flex;
  align-items: center;

  ${theme.below.lg} {
    padding: 2rem;
  }

  ${theme.below.sm} {
    padding: 2.5rem 1.25rem;
    justify-content: space-between;
  }

  span {
    margin-right: 2rem;
  }

  p {
    font-size: 16px;
    color: ${theme.colors.mediumgrey};
  }
`;

const Wrapper = styled('section')`
  text-align: left;
  font-size: 16px;
  color: ${theme.colors.white};
  background: ${backgroundColor};
  margin: 0;

  h2 {
    font-size: 26px;
    margin-bottom: 1.5rem;

    ${theme.below.sm} {
      font-size: 22px;
      margin-bottom: 1.25rem;
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  p {
    line-height: 1.6;

    + p {
      margin-top: 1rem;
    }
  }

  a {
    color: ${theme.colors.white};
    text-decoration: none;
    line-height: 1.6;

    :hover {
      color: ${theme.colors.white};
      transition: all 0.3s linear;
    }
  }
`;

const UpperSection = styled(MaxWidth)`
  max-width: 90rem;
  padding: 0 2rem;
  line-height: 1.6;

  ${theme.below.sm} {
    padding: 0 1.25rem;
  }
`;

const FooterLogo = styled('div')`
  display: flex;
  justify-content: flex-start;
  padding: 6.25rem 0 5rem;

  h2 {
    width: auto;
  }

  a {
    padding: 0;
  }

  ${theme.below.sm} {
    padding: 3rem 0 2rem;

    img {
      width: 156px;
    }
  }
`;

const WrapFooterNav = styled(MaxWidth)`
  padding: 0;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;

  ${theme.below.md} {
      // background: ${backgroundColor};
    // padding-top: 2rem;
    // padding-bottom: 2rem;
  }

  > section {
    padding: 0 4rem 0 0;
    min-width: 14rem;
    max-width: 20rem;

    &:last-child {
      padding-right: 0;
    }

    &.footer-column-1 {

      ${theme.above.lg} {
        max-width: none;
        width: 30rem;
        padding-right: 3rem;
      }

      ${theme.above.xl} {
        padding-right: 8rem;
      }
    }

    .footer-links li {
      + li {
        //margin-top: 0.5rem;
      }
    }

  }


  ${theme.below.xl} {

    > section {
      padding-right: 2rem;
    }
  }

  ${theme.below.lg} {
    flex-wrap: wrap;

    > section {
      width: 50%;
      padding: 0 4rem 4rem 0;
      max-width: none;

      &:nth-child(2) {
        padding-right: 0;
      }

    }
  }


  ${theme.below.sm} {
    flex-direction: column;
    justify-content: center;
    //text-align: left;
    font-size: 14px;

    > section {
      margin: 0;
      padding: 0 0 3rem;
      width: 100%;
    }
  }

`;

const DividerLine = styled('div')`
  border-top: 1px solid #4e4e4e;
  margin: 7.75rem 2rem 0;

  ${theme.below.lg} {
    margin-top: 0rem;
  }

  ${theme.below.sm} {
    margin: 0 1.25rem;
  }
`;

const Footer = () => {
  const contentQuery = useQuery(categoryContentQuery, {
    variables: {
      id: theme.settings.footerCategoryId
    }
  });
  let contentItems = contentQuery.data?.category?.data?.items;
  const date = new Date();
  const year = date.getFullYear();
  const { loggedIn } = useAuth();
  const { selectedChannel } = useContext(ChannelContext);
  const isB2b = selectedChannel.name === 'B2B';
  const isLoggedOutB2b = !loggedIn && isB2b;

  return (
    <Wrapper>
      {!isLoggedOutB2b && (
        <>
          <NewsletterAndCatalogSection>
            <NewsletterSignup />
            <OrderCatalog />
          </NewsletterAndCatalogSection>
          <UpperSection>
            <FooterLogo>
              <Logo />
            </FooterLogo>

            <WrapFooterNav>
              <section className="footer-column-1">
                {contentItems && contentItems.length > 0 && (
                  <ContentRenderer
                    items={[contentItems[0]]}
                    components={contentComponents}
                  />
                )}
                <SocialLinks showLabel={false} />
              </section>
              <section className="footer-column-2">
                {contentItems && contentItems.length > 1 && (
                  <ContentRenderer
                    items={[contentItems[1]]}
                    components={contentComponents}
                  />
                )}
                <FooterLinks />
              </section>
              <section className="footer-column-3">
                {contentItems && contentItems.length > 2 && (
                  <ContentRenderer
                    items={[contentItems[2]]}
                    components={contentComponents}
                  />
                )}
              </section>
              <section className="footer-column-4">
                {contentItems && contentItems.length > 3 && (
                  <ContentRenderer
                    items={[contentItems[3]]}
                    components={contentComponents}
                  />
                )}
              </section>
            </WrapFooterNav>
          </UpperSection>
          <DividerLine />
        </>
      )}

      <PoweredByWrapper>
        <span>© {year} Fjäråskupan</span>
      </PoweredByWrapper>
    </Wrapper>
  );
};

export default Footer;
