export const FALLBACK_IMAGE =
  'https://res.cloudinary.com/dragster/image/upload/v1/Fjaraskupan_2020/error.png';
export const IMAGE_BASE_URL =
  'https://res.cloudinary.com/dragster/image/upload/Fjaraskupan_2020/';

export const KITCHEN_ENV_KEY = 'kitchen-environment';
export const KITCHEN_COLOR_KEY = 'kitchen-color';
export const FAN_TYPE_KEY = 'fan-type';
export const FAN_MODEL_KEY = 'fan-model';
export const CONTENT_SHOWROOM = 'Showroom';
