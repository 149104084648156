import React from 'react';
import { styled } from 'linaria/react';

import { theme } from '../../Theme';

const ContentContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  text-align: left;
  margin: 0 auto;
  max-width: 75rem;
  padding: 2rem 2rem;
  flex-wrap: wrap;

  &.items-1 > * {
    width: 100%;
  }

  &.items-2 > * {
    width: calc(50% - 1rem);
  }

  &.items-3 > * {
    width: calc(33.3333% - 1.3333rem);

    ${theme.below.md} {
      width: 100%;
      //width: calc(50% - 1rem);
      //margin-top: 2rem;
      //
      //&:first-child {
      //  width: 100%;
      //  margin: 0;
      //}
    }
  }

  &.items-4 > * {
    width: calc(25% - 1.5rem);

    ${theme.below.lg} {
      width: calc(50% - 1rem);

      &:nth-child(3),
      &:nth-child(4) {
        margin-top: 2rem;
      }
    }

    ${theme.below.md} {
      width: calc(50% - 0.625rem);

      &:nth-child(3),
      &:nth-child(4) {
        margin-top: 1.25rem;
      }
    }

    ${theme.below.sm} {
      width: 100%;

      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        margin-top: 1.25rem;
      }
    }
  }

  &.items-5 > * {
    width: calc(20% - 2rem);
  }

  ${theme.below.md} {
    //flex-flow: column;
    padding: 1.25rem 1.25rem;
  }
`;

export default ContentContainer;
