import React from 'react';
import { styled } from 'linaria/react';
import getContentValue from "../getContentValue";

const Paragraph = styled('p')``;

const SingleParagraph = ({ text }) => {
  text = getContentValue(text);
  return <Paragraph>{text}</Paragraph>;
};

export default SingleParagraph;
