import React from 'react';
import getContentValue from "../getContentValue";

const SingleRawHtmlBlock = ({ html }) => {
  html = getContentValue(html);
  return (
    <div
      className="html-container"
      dangerouslySetInnerHTML={{ __html: html }}
    ></div>
  );
};

export default SingleRawHtmlBlock;
