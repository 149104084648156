import {
  FAN_TYPE_KEY,
  KITCHEN_COLOR_KEY,
  KITCHEN_ENV_KEY
} from './ShowroomConstants';

export function generateCodeString(selectedFanModel, selectedOptions) {
  const fanObj = selectedOptions.find(opt => opt.id === FAN_TYPE_KEY);
  const fanIndex = selectedOptions.indexOf(fanObj);

  if (fanIndex !== -1) {
    selectedOptions.splice(fanIndex, 1);
    selectedOptions.unshift(fanObj);
  }

  //Generate new image url format when "Fläktmodell" is in active selection
  //const selectedFanModel = selectedOptions.find(opt => opt.id === 'Fläktmodell');
  if (selectedFanModel) {
    let modelOptions = selectedOptions.slice(4);
    let modelString = modelOptions.map(obj => obj.value).join('_') + '_2_';
    const kitchen = selectedOptions.find(opt => opt.id === KITCHEN_ENV_KEY)
      ?.value;
    const kitchenColor = selectedOptions.find(
      opt => opt.id === KITCHEN_COLOR_KEY
    )?.value;

    // console.log(modelString + `${kitchen}_${kitchenColor}`)
    return modelString + `${kitchen}_${kitchenColor}`;
  }

  return selectedOptions.map(obj => obj.value).join('_');
}

export function generateRandomConfigKey(length) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let result = '';
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters[randomIndex];
  }
  return result;
}

export function getAllFanModelDefaultValues(choice) {
  let products = [];
  if (choice?.options && choice?.options?.length > 0) {
    let option = choice.options[0];
    if (option.singleChoiceProducts) {
      products.push({
        key: choice?.choiceHeading,
        products: option.singleChoiceProducts
      });
    }
    if (option.includedProducts) {
      products.push({
        key: choice?.choiceHeading,
        products: option.includedProducts
      });
    }
    if (option.choice) {
      let choiceProducts = getAllFanModelDefaultValues(option.choice);
      products = products.concat(choiceProducts);
    }
  }

  return products;
}

export async function fetchProductFile(key) {
  const currentHostname = window.location.hostname;
  try {
    let response = null;
    if (currentHostname == 'fjaraskupan.se') {
      response = await fetch(
        `https://fjaraskupan.se/pub_docs/files/konfigurationer/${key}`
      );
    } else {
      response = await fetch(`/api/showroom/${key}`);
    }

    if (!response.ok) {
      throw new Error('Failed to fetch fan configuration file');
    }
    const data = await response.json();
    // Process the data
    return data;
  } catch (error) {
    // Handle errors
    console.error(error);
    return null;
  }
}
