import React from 'react';
import { styled } from 'linaria/react';
import CategoryLink from '@jetshop/ui/CategoryLink';

const Wrapper = styled('div')`
  //display: flex;
  //flex-flow: column;
`;

const Heading = styled('h2')``;

const SubMenuSection = ({
  lv2Cat,
  onSelect = () => {},
  isActive,
  lv2Index,
  setVisibleLv3SubCat,
  shouldRenderLv3
}) => {
  return (
    <Wrapper className={isActive ? 'show-subcategories lv2' : 'lv2'}>
      <Heading>
        <CategoryLink
          onClick={onSelect}
          category={lv2Cat}
          onMouseOver={() => {
            setVisibleLv3SubCat(lv2Index);
          }}
        >
          <span>{lv2Cat.name}</span>
        </CategoryLink>
      </Heading>
      <div className={'lv3'}>
        <ul>
          {shouldRenderLv3 &&
            lv2Cat.subcategories.map(cat => (
              <li key={cat.id}>
                <CategoryLink onClick={onSelect} category={cat}>
                  <span>{cat.name}</span>
                </CategoryLink>
              </li>
            ))}
        </ul>
      </div>
    </Wrapper>
  );
};

export default SubMenuSection;
