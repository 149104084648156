import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../Theme';
import getContentValue from "../getContentValue";

const Wrapper = styled('div')`
  max-width: 38rem;
  position: relative;
  margin: 2rem 0 3rem;

  iframe {
    display: block;
    width: 100%;
  }

  ${theme.below.xl} {
  }

  ${theme.below.lg} {
  }
`;

const VideoContainer = styled('div')`
  background-color: ${theme.colors.grey};
  width: 100%;
  position: relative;
  padding-top: 57%;

  iframe {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
  }
`;

const ArticleVideo = ({ videoId }) => {
  videoId = getContentValue(videoId);
  return (
    <Wrapper>
      <VideoContainer>
        <iframe
          src={'https://www.youtube.com/embed/' + videoId}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </VideoContainer>
    </Wrapper>
  );
};

export default ArticleVideo;
