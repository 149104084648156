import React from 'react';
import { styled } from 'linaria/react';
import Image from '@jetshop/ui/Image/Image';
import { Price } from '@jetshop/ui/Price';
import { theme } from '../../Theme';
import { Link } from 'react-router-dom';
import t from '@jetshop/intl';
import { Above } from '@jetshop/ui/Breakpoints';
import PriceSection from '../../ProductPage/PriceSection';

const ProductCarouselItemWrapper = styled('div')`
  padding: 2rem;
  position: relative;
  //background: #fcc;

  ${theme.below.md} {
    + div {
    }
  }
  ${theme.below.lg} {
    padding: 0 1rem;
  }
`;
const Container = styled(Link)`
  width: 100%;
  display: block;
  color: ${theme.colors.black};
  text-decoration: none;

  ${theme.below.md} {
    width: 90%;
  }

  &:hover .cta-text {
    padding-left: 20px;
  }
`;

const ImageContainer = styled('div')`
  background: #f7f7f7;
`;

const ContentContainer = styled('div')`
  background: ${theme.colors.background};
  padding: 0 1rem 0.75rem;
  line-height: 1.35;
  //position: absolute;
  bottom: 0;
  left: 0;
  right: 0px;
  text-align: center;

  font-size: 18px;
  font-family: ${theme.fonts.secondary};

  ${theme.below.xl} {
    left: calc(80% - 3rem);
  }

  ${theme.below.md} {
    top: 20px;
    font-size: 15px;
    left: calc(90% - 2rem);
  }

  .price-column {
    flex-direction: column;
    align-items: center;
  }
`;

const Title = styled('h3')`
  font-size: 24px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-top: 0.75rem;
  line-height: 1.2;
`;

const CtaText = styled('div')`
  text-align: center;
  padding: 20px 0 10px;
  font-size: 16px;
  transition: 0.2s;

  ${theme.below.md} {
    font-size: 14px;
  }
`;

const ProductCarouselItem = product => {
  product = product?.product?.value;

  if (!product || !product.images.length) {
    return null;
  }

  const image = product.images[0];
  // console.log(product, " - product");

  return (
    <ProductCarouselItemWrapper>
      <Container to={product.primaryRoute.path}>
        <ImageContainer>
          <Above breakpoint="md">
            {matches =>
              matches ? (
                <Image
                  src={image.url}
                  alt={image.alt}
                  sizes={[1, 0.5, 1 / 3, 1 / 3]}
                  aspect="4:5"
                ></Image>
              ) : (
                <Image
                  src={image.url}
                  alt={image.alt}
                  sizes={[1, 0.5, 1 / 3, 1 / 3]}
                  aspect="1:1"
                ></Image>
              )
            }
          </Above>
        </ImageContainer>
        <ContentContainer>
          <Title>{product.name}</Title>
          {/* <Price price={product.price} previousPrice={product.previousPrice} /> */}
          <PriceSection
            price={product.price}
            previousPrice={product.previousPrice}
            className="price-column"
          />
        </ContentContainer>
        <CtaText className={'cta-text'}>
          {t('Buy now')} {`>`}
        </CtaText>
      </Container>
    </ProductCarouselItemWrapper>
  );
};

export default ProductCarouselItem;
