import {styled} from 'linaria/react';
import React, {useState, useEffect} from 'react';
import CategoryHeader from '../CategoryHeader';
import MaxWidth from '../../Layout/MaxWidth';
import {theme} from '../../Theme';
import CategoryHeaderLoadingState from '../CategoryHeaderLoadingState';
import {useQuery} from 'react-apollo';
import blogArticleQuery from './BlogArticleQuery.gql';
import BlogArticleBlurb from './BlogArticleBlurb';
import KeywordFilter from './KeywordFilter';
import {Above, Below, Between} from '@jetshop/ui/Breakpoints';
import ArticlePagination from '../../ui/ArticlePagination';
import {TrackPageLoad} from '../../DataLayer/DataLayer';
import getContentValue from "../../CustomContent/getContentValue";

const Container = styled(MaxWidth)`
  padding-top: 2rem;

  ${theme.above.sm} {
    padding-top: 2rem;
  }
`;

const CategoryWrapper = styled('div')``;

const BlurbContainer = styled('div')`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -0.5rem;

  > * {
    width: 25%;

    ${theme.below.lg} {
      width: 33.33333%;
    }

    ${theme.below.md} {
      width: 50%;
    }

    ${theme.below.sm} {
      width: 100%;
    }
  }
`;

const extractKeywords = articles => {
  let keywordList = [];
  if (articles && articles.length) {
    articles.forEach((article, index) => {
      const keywords = article.data?.items[0]?.children;

      if (keywords && keywords.length) {
        keywords.forEach((keywordData, index) => {
          if (keywordData.type.match(/keyword/i)) {
            const keyword = getContentValue(keywordData.properties[0])
              .trim()
              .toLowerCase();
            if (!keywordList.includes(keyword)) {
              keywordList.push(keyword);
            }
          }
        });
      }
    });
  }
  return keywordList;
};

const articleKeywordsAreActive = (article, activeKeywords) => {
  let foundMatch = false;
  const articleKeywords = extractKeywords([article]);
  articleKeywords.forEach(keyword => {
    if (activeKeywords.includes(keyword)) {
      foundMatch = true;
    }
  });
  return foundMatch;
};

const filterArticles = (allArticles, activeKeywords) => {
  let filteredArticles = [];
  if (activeKeywords && activeKeywords.length) {
    allArticles.forEach(article => {
      if (articleKeywordsAreActive(article, activeKeywords)) {
        filteredArticles.push(article);
      }
    });
  }
  else filteredArticles = allArticles;
  return filteredArticles;
};

const sortArticlesByDate = articles => {
  let sortedArticles = [];
  if (articles) {
    articles.forEach((article, index) => {
      const articleInfo = article.data?.items[0];
      let hasArticleInfo =
        articleInfo && articleInfo.type === 'Blog Article Info';
      let year = '0';
      let month = '0';
      let day = (9999 - index).toString();
      let articleWithDate = {...article};

      if (hasArticleInfo) {
        year = getContentValue(articleInfo.properties[0]);
        month = getContentValue(articleInfo.properties[1]);
        day = getContentValue(articleInfo.properties[2]);
      }
      articleWithDate.dateStamp = parseInt(year + month + day);
      sortedArticles.push(articleWithDate);
    });
    sortedArticles.sort(function (a, b) {
      return b.dateStamp - a.dateStamp;
    });
  }
  return sortedArticles;
};

const BlogContent = ({blogCatId}) => {
  const articleQuery = useQuery(blogArticleQuery, {
    variables: {
      id: blogCatId
    }
  });

  const [articlesPerPage, setArticlesPerPage] = useState(2);
  const [activeKeywords, setActiveKeywords] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const allArticles = sortArticlesByDate(
    articleQuery.data?.category?.subcategories
  );
  const allKeywords = extractKeywords(allArticles);
  let filteredArticles = filterArticles(allArticles, activeKeywords);
  let currentPageArticles = !!filteredArticles
    ? filteredArticles.slice(
      (currentPage - 1) * articlesPerPage,
      currentPage * articlesPerPage
    )
    : [];


  const ArticleQtySetter = ({articleQty}) => {
    useEffect(() => {
      setArticlesPerPage(articleQty);
    });
    return null;
  };

  return (
    <>
      <KeywordFilter
        allKeywords={allKeywords}
        activeKeywords={activeKeywords}
        setActiveKeywords={setActiveKeywords}
        setCurrentPage={setCurrentPage}
      />
      <BlurbContainer>
        {currentPageArticles &&
        currentPageArticles.map((article, index) => {
          return <BlogArticleBlurb key={article.name} article={article}/>;
        })}
      </BlurbContainer>
      {filteredArticles && (
        <ArticlePagination
          articlesPerPage={articlesPerPage}
          articleQty={filteredArticles.length}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )}
      <Below breakpoint={'sm'}>
        <ArticleQtySetter articleQty={6}/>
      </Below>
      <Between breakpoints={['sm', 'lg']}>
        <ArticleQtySetter articleQty={12}/>
      </Between>
      <Above breakpoint={'lg'}>
        <ArticleQtySetter articleQty={16}/>
      </Above>
    </>
  );
};

const BlogMainPage = ({category, result}) => {
  if (!category) {
    return (
      <CategoryWrapper>
        <CategoryHeaderLoadingState/>;
      </CategoryWrapper>
    );
  }

  let trackingPageType = 'Blogg';
  if (category.id === theme.settings.faqCategoryId) {
    trackingPageType = 'Faq';
  }

  return (
    <CategoryWrapper>
      <TrackPageLoad pageType={trackingPageType} route={category.primaryRoute}/>
      <>
        <CategoryHeader
          category={category}
          parents={result?.data?.route?.parents}
        />
        <Container>
          <BlogContent blogCatId={category.id}/>
        </Container>
      </>
    </CategoryWrapper>
  );
};

export default BlogMainPage;
