import React from 'react';
import {styled} from 'linaria/react';
import {theme} from '../../Theme';
import {useQuery} from "react-apollo";
import categoryContentQuery from "../../CustomContent/CategoryContentQuery.gql";
import {ContentRenderer} from "@jetshop/ui/ContentRenderer";
import contentComponents from "../../CustomContent/elementMap";
import Image from "@jetshop/ui/Image/Image";

import { baseStyles } from '../../ui/Button';
import getContentValue from "../../CustomContent/getContentValue";


const OuterWrapper = styled('div')`
  height: 350px;
  width: 50%;
  flex-shrink: 0;
  background: ${theme.colors.background};


  ${theme.below.lg} {
    width: 100%;
    height: auto;
    justify-content: center;
  }


  ${theme.below.md} {
    height: auto;
  }
  
  > [data-flight-image-container] {
    height: 100% !important;
    padding: 0 !important;
  }

`;


const Wrapper = styled('div')`
  width: 100%;
  display: flex;
  color: ${theme.colors.black};
  align-items: center;
  max-width: 45rem;
  padding: 2rem;
  height: 100%;
  
  ${theme.below.lg} {
    margin: 0 auto;
  }


  ${theme.below.md} {
    padding: 4rem 2rem;
  }

  ${theme.below.sm} {
    padding: 3rem 1.25rem;
    flex-wrap: wrap;
  }


  h3 {
    font-size: 18px;

    ${theme.below.sm} {
      font-size: 16px;
    }

  }

  h2 {
    font-size: 26px;
    line-height: 1.3;
    max-width: 270px;
    margin-top: 1rem;

    ${theme.below.sm} {
      font-size: 22px;
    }

  }
  
  p {
    display: flex;
    margin-bottom: 2rem;

    ${theme.below.sm} {
      flex-wrap: wrap;
      margin-bottom: 0;
      
      a {
        margin-bottom: 1rem;
        min-width: 240px;
        + a {
        }
      }
    }
    
  }
  
  a {
    ${baseStyles};
    min-width: 180px;
    
    &:first-child {
      margin-right: 2rem;
    }
  }

`;


const OrderCatalog = () => {
  const contentQuery = useQuery(categoryContentQuery, {
    variables: {
      id: theme.settings.catalogFooterAreaCategoryId
    }
  });
  let contentItems = contentQuery.data?.category?.data?.items;
  let bgImage = contentItems && contentItems.length > 0 ? contentItems[0] : null;
  bgImage = bgImage ? getContentValue(bgImage.properties[0]) : null;
  let contentCode = contentItems && contentItems.length > 1 ? contentItems[1] : null;

  return (
    <OuterWrapper>

      {bgImage && <Image src={bgImage} cover={true} sizes={[1, 1, 1, 0.5]} crop={true}>
        <Wrapper>
          {contentCode && (
            <ContentRenderer
              items={[contentCode]}
              components={contentComponents}
            />
          )}
        </Wrapper>

      </Image>}
    </OuterWrapper>
  )
};

export default OrderCatalog;