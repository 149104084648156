import React from 'react';
import { styled } from 'linaria/react';
import Image from '@jetshop/ui/Image/Image';
import { Link } from 'react-router-dom';
import { theme } from '../../Theme';
import { Above } from '@jetshop/ui/Breakpoints';
import getContentValue from "../getContentValue";

const CategoryLinkItemWrapper = styled('div')`
  position: relative;
  padding-bottom: 2rem;

  ${theme.below.md} {
    padding-bottom: 2.5rem;

    + div {
    }
  }
  ${theme.below.md} {
  }
`;
const Container = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  color: ${theme.colors.black};
  text-decoration: none;
`;

const SmallTitle = styled('h4')`
  font-size: 1rem;
  line-height: 26px;
  padding-bottom: 0.75rem;
  text-align: center;

  ${theme.below.md} {
    font-size: 14px;
  }
`;

const BigTitle = styled('h2')`
  font-size: 26px;
  line-height: 1.3;
  text-align: center;

  ${theme.below.md} {
    font-size: 22px;
  }
`;

const Divider = styled('div')`
  width: 84px;
  border-bottom: 2px solid ${theme.colors.black};
  margin: 1rem 0 2rem;

  ${theme.below.md} {
    margin-bottom: 1.75rem;
  }
`;

const CategoryLinkItem = ({ image, link, smallTitle, bigTitle }) => {
  image = getContentValue(image);
  link = getContentValue(link);
  smallTitle = getContentValue(smallTitle);
  bigTitle = getContentValue(bigTitle);

  return (
    <CategoryLinkItemWrapper>
      <Container to={link}>
        {smallTitle && <SmallTitle>{smallTitle}</SmallTitle>}
        {bigTitle && <BigTitle>{bigTitle}</BigTitle>}
        {(bigTitle || smallTitle) && <Divider />}
        <Above breakpoint="md">
          {matches =>
            matches ? (
              <Image
                src={image}
                sizes={[1, 1, 1, 1]}
                aspect="5:3"
                cover={true}
              ></Image>
            ) : (
              <Image
                src={image}
                sizes={[1, 1, 1, 1]}
                aspect="4:3"
                cover={true}
              ></Image>
            )
          }
        </Above>
      </Container>
    </CategoryLinkItemWrapper>
  );
};

export default CategoryLinkItem;
