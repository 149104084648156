import { styled } from 'linaria/react';
import React, { useContext } from 'react';
import CategoryHeader from '../CategoryPage/CategoryHeader';
import MaxWidth from '../Layout/MaxWidth';
import { theme } from '../Theme';
import CategoryHeaderLoadingState from './CategoryHeaderLoadingState';
import { TrackPageLoad } from '../DataLayer/DataLayer';
import { ContentRenderer } from '@jetshop/ui/ContentRenderer';
import contentComponents from '../CustomContent/elementMap';
import ArticleContainer from '../CustomContent/Common/ArticleContainer';
import Spinner from '../ui/Spinner';
import ChannelContext from '@jetshop/core/components/ChannelContext';

const Container = styled(MaxWidth)`
  padding-top: 2rem;

  ${theme.above.sm} {
    padding-top: 2rem;
  }
`;

const CategoryWrapper = styled('div')``;

const IframeContainer = styled('div')`
  padding: 0 1rem;
  position: relative;

  ${theme.below.sm} {
    padding: 0 1rem;
  }

  iframe {
    display: block;
    width: 100%;
    height: 1200px;
    background: transparent;
    position: relative;
    z-index: 2;

    ${theme.below.lg} {
      height: 1000px;
    }
  }
`;

const IframeSpinner = styled(Spinner)`
  position: absolute;
  top: 5rem;
  left: 49%;
  z-index: 1;
`;

const Category = ({ category, result }) => {
  const {
    selectedChannel: {
      language: { culture }
    }
  } = useContext(ChannelContext);

  const iframes = {
    'sv-SE': 'https://fjaraskupan.hemsida.eu/vara-aterforsaljare-jetshop/',
    'nb-NO': 'https://fjaraskupan.hemsida.eu/vara-aterforsaljare-jetshop-no/',
    'fi-FI': 'https://fjaraskupan.hemsida.eu/vara-aterforsaljare-jetshop-fi/'
  };

  const iframeSrc = iframes[culture] || iframes['sv-SE'];
  if (!category) {
    return (
      <CategoryWrapper>
        <CategoryHeaderLoadingState />;
      </CategoryWrapper>
    );
  }
  let contentItems = [...category.data.items];
  return (
    <CategoryWrapper>
      <TrackPageLoad
        pageType={'Återförsäljare'}
        route={category.primaryRoute}
      />

      <CategoryHeader
        category={category}
        parents={result?.data?.route?.parents}
      />

      {contentItems.length > 0 && (
        <Container>
          <ArticleContainer>
            <ContentRenderer
              items={contentItems}
              components={contentComponents}
            />
          </ArticleContainer>
        </Container>
      )}

      <IframeContainer>
        <iframe id="aterforsaljare" frameBorder="0" src={iframeSrc}></iframe>
        <IframeSpinner />
      </IframeContainer>
    </CategoryWrapper>
  );
};

export default Category;
