import React from 'react';
import { styled } from 'linaria/react';
import ContentContainer from '../Common/ContentContainer';

import { theme } from '../../Theme';

const Wrapper = styled('div')`
  background-color: ${theme.colors.white};

  ${theme.below.xl} {
  }
  ${theme.below.md} {
  }
`;
const Container = styled(ContentContainer)`
  max-width: 75rem;
  align-items: flex-end;

  ${theme.below.md} {
    &.items-2 > div {
      width: 100%;
    }
  }
`;

const CategoryLinkRow = ({ children }) => {
  return (
    <Wrapper>
      <Container className={'items-' + children.length}>{children}</Container>
    </Wrapper>
  );
};

export default CategoryLinkRow;
