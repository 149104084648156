import React from 'react';
import { styled } from 'linaria/react';
import { css } from 'linaria';
import { TrendLink } from '../../ui/Button';
import ContentContainer from '../Common/ContentContainer';
import { theme } from '../../Theme';
import getContentValue from "../getContentValue";

const Wrapper = styled('div')`
  display: flex;
  align-items: center;
  padding: 4rem 0;
  background-color: ${theme.colors.black};

  ${theme.below.xl} {
  }

  ${theme.below.lg} {
    flex-flow: column;
    align-items: flex-start;
    padding: 3rem 0 4rem;
  }
`;

const VideoContainer = styled('div')`
  background-color: ${theme.colors.grey};
  width: 62%;
  position: relative;

  ${theme.below.xl} {
  }

  ${theme.below.lg} {
    width: 96%;
  }

  div {
    padding-top: 50%;
  }

  iframe {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
  }
`;

const TextContainer = styled(ContentContainer)`
  flex-direction: column;
  justify-content: center;
  color: white;
  height: 100%;
  text-align: left;
  margin: 0;
  position: relative;
  left: -4rem;
  text-shadow: 0 0 2px ${theme.colors.black};

  ${theme.below.lg} {
    justify-content: flex-end;
    padding-bottom: 0rem;
    left: 0;
  }

  > * {
    max-width: 500px;
  }
`;
const Title = styled('h1')`
  font-size: 40px;
  margin-bottom: 18px;

  ${theme.below.md} {
    font-size: 34px;
    margin-bottom: 12px;
  }
`;

const Description = styled('p')`
  font-size: 16px;
  font-weight: ${theme.fontWeights.medium};
  margin-bottom: 30px;
  line-height: 1.45em;

  ${theme.below.md} {
    width: 100%;
    font-size: 14px;
  }
`;

const StyledButton = styled(TrendLink)`
  background: transparent;
  border: 1px solid ${theme.colors.white};
  min-width: 232px;

  ${theme.below.md} {
    min-width: 190px;
  }
`;

const heroStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const VideoRow = ({
  videoId,
  headingText,
  bodyText,
  buttonText,
  buttonLink
}) => {
  videoId = getContentValue(videoId);
  headingText = getContentValue(headingText);
  bodyText = getContentValue(bodyText);
  buttonText = getContentValue(buttonText);
  buttonLink = getContentValue(buttonLink);

  return (
    <Wrapper>
      <VideoContainer>
        <div>
          <iframe
            src={'https://www.youtube.com/embed/' + videoId}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </VideoContainer>
      <TextContainer>
        {headingText && <Title>{headingText}</Title>}
        {bodyText && <Description>{bodyText}</Description>}
        {buttonText && buttonLink && (
          <StyledButton to={buttonLink}>{buttonText}</StyledButton>
        )}
      </TextContainer>
    </Wrapper>
  );
};

export default VideoRow;
