import DynamicRoute from '@jetshop/core/components/DynamicRoute';
import CustomFont from '@jetshop/core/components/Fonts/CustomFont';
import PaginationProvider from '@jetshop/core/components/Pagination/PaginationProvider';
import { ProductListProvider } from '@jetshop/core/hooks/ProductList/ProductListContext';
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';
import GenericError from '@jetshop/ui/ErrorBoundary/Generic';
import LoadingBar from '@jetshop/ui/Loading/LoadingBar';
import ModalProvider from '@jetshop/ui/Modal/ModalProvider';
import ModalRoot from '@jetshop/ui/Modal/ModalRoot';
import ScrollRestorationHandler from '@jetshop/ui/ScrollRestorationHandler';
import React, { useState, useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import loadable from '@loadable/component';
import { Route, Switch } from 'react-router-dom';
import loadFontCss from '../fonts/loadFontCss';
import CategoryPreviewQuery from './CategoryPreviewQuery.gql';
import PagePreviewQuery from './PagePreviewQuery.gql';
import Container from './Layout/Container';
import Content from './Layout/Content';
import Footer from './Layout/Footer/Footer';
import Header from './Layout/Header/Header';
import LoadingPage from './LoadingPage';
import { Favourites } from './ProductList/Favourites';
import { productListQueries } from './ProductList/productListQueries';
import ProductPreviewQuery from './ProductPreviewQuery.gql';
import routeQuery from './RouteQuery.gql';
import { theme } from './Theme';
import '../globalStyles';
import CookieConsent from './CookieConsent';
import Imbox from './Imbox';
import ChannelContext from '@jetshop/core/components/ChannelContext';

const Store = loadable(() => import('./Store/Store'), {
  fallback: <LoadingPage />
});

const StoreLocator = loadable(() => import('./StoreLocator/StoreLocator'), {
  fallback: <LoadingPage />
});

const NotFound = loadable(() => import('./NotFoundPage'), {
  fallback: <LoadingPage />
});

const LogInPage = loadable(() => import('./Auth/LogInPage'), {
  fallback: <LoadingPage />
});

const LogOutPage = loadable(
  () => import('@jetshop/core/components/AuthContext/LogOut'),
  {
    fallback: <LoadingPage />
  }
);

const PreviewRoute = loadable(
  () => import('@jetshop/core/components/DynamicRoute/PreviewRoute'),
  {
    fallback: <LoadingPage />
  }
);

const NavTreePage = loadable(() => import('./NavigationTree/NavTreePage'), {
  fallback: <LoadingPage />
});

const LoadableStartPage = loadable(() => import('./StartPage/StartPage'), {
  fallback: <LoadingPage />
});

export const LoadableProductPage = loadable(
  () => import('./ProductPage/ProductPage'),
  {
    fallback: <LoadingPage />
  }
);

export const LoadableCategoryPage = loadable(
  () => import('./CategoryPage/CategoryPage'),
  {
    fallback: <LoadingPage />
  }
);

export const LoadableContentPage = loadable(
  () => import('./ContentPage/ContentPage'),
  {
    fallback: <LoadingPage />
  }
);

const LoadableSearchPage = loadable(() => import('./SearchPage/SearchPage'), {
  fallback: <LoadingPage />
});
const LoadableSignUpPage = loadable(() => import('./Auth/Signup/SignUpPage'), {
  fallback: <LoadingPage />
});

const ForgotPassword = loadable(() => import('./Auth/ForgotPassword'), {
  fallback: <LoadingPage />
});

const ResetPassword = loadable(() => import('./Auth/ResetPassword'), {
  fallback: <LoadingPage />
});
const LoadableMyPages = loadable(() => import('./MyPages/MyPages'), {
  fallback: <LoadingPage />
});

export const TransparencyContext = React.createContext(null);

function Shop() {
  const { routes } = useShopConfig();
  const selectedChannel = useContext(ChannelContext)?.selectedChannel;

  // HEADER TRANSPARENCY STATE
  const [headerIsOpaque, setHeaderAsOpaque] = useState(true);

  return (
    <GenericError>
      <ModalProvider>
        <TransparencyContext.Provider
          value={{ headerIsOpaque, setHeaderAsOpaque }}
        >
          <Container>
            <LoadingBar color={theme.colors.loadingBar} />
            <CustomFont
              primaryFont={theme.fonts.primary}
              injectCss={loadFontCss}
            />
            <Helmet titleTemplate="%s" defaultTitle="Fjäråskupan">
              {selectedChannel?.id == 4 && (
                <meta name="robots" content="noindex" />
              )}
            </Helmet>
            <Imbox />
            <ProductListProvider queries={productListQueries}>
              <Header
                headerIsOpaque={headerIsOpaque}
                setHeaderAsOpaque={setHeaderAsOpaque}
              />
              <Content>
                <PaginationProvider defaultProductsPerPage={50}>
                  <Switch>
                    <Route exact path="/">
                      <LoadableStartPage
                        headerIsOpaque={headerIsOpaque}
                        setHeaderAsOpaque={setHeaderAsOpaque}
                      />
                    </Route>
                    <Route path="/favourites">
                      <Favourites />
                    </Route>
                    <Route path={routes.search.path}>
                      <LoadableSearchPage
                        headerIsOpaque={headerIsOpaque}
                        setHeaderAsOpaque={setHeaderAsOpaque}
                      />
                    </Route>
                    <Route path={routes.signup.path}>
                      <LoadableSignUpPage />
                    </Route>
                    <Route path={routes.login.path}>
                      <LogInPage
                        headerIsOpaque={headerIsOpaque}
                        setHeaderAsOpaque={setHeaderAsOpaque}
                      />
                    </Route>
                    <Route path={routes.logout.path}>
                      <LogOutPage />
                    </Route>
                    <Route path={routes.stores.path}>
                      <StoreLocator />
                    </Route>
                    <Route path={`${routes.store.path}/:id`}>
                      <Store />
                    </Route>
                    <Route path={routes.tree.path}>
                      <NavTreePage />
                    </Route>
                    <Route path={routes.myPages.path}>
                      <LoadableMyPages
                        headerIsOpaque={headerIsOpaque}
                        setHeaderAsOpaque={setHeaderAsOpaque}
                      />
                    </Route>
                    <Route exact path={routes.forgotPassword.path}>
                      <ForgotPassword />
                    </Route>
                    <Route path={`${routes.resetPassword.path}/:token`}>
                      <ResetPassword />
                    </Route>
                    <Route
                      path="/preview"
                      render={props => (
                        <PreviewRoute
                          productQuery={ProductPreviewQuery}
                          categoryQuery={CategoryPreviewQuery}
                          pageQuery={PagePreviewQuery}
                          productPage={LoadableProductPage}
                          categoryPage={LoadableCategoryPage}
                          contentPage={LoadableContentPage}
                          StartPage={LoadableStartPage}
                          {...props}
                        />
                      )}
                    />
                    <DynamicRoute
                      routeQuery={routeQuery}
                      productPage={LoadableProductPage}
                      categoryPage={LoadableCategoryPage}
                      contentPage={LoadableContentPage}
                      notFoundPage={NotFound}
                      LoadingPage={LoadingPage}
                      headerIsOpaque={headerIsOpaque}
                      setHeaderAsOpaque={setHeaderAsOpaque}
                    />
                  </Switch>
                </PaginationProvider>
              </Content>
              <Footer />
            </ProductListProvider>
            <ModalRoot />
            <ScrollRestorationHandler
              ignoreForRouteTypes={[
                'sortOrderChange',
                'filterChange',
                'paginationChange'
              ]}
            />
            <CookieConsent />
          </Container>
        </TransparencyContext.Provider>
      </ModalProvider>
    </GenericError>
  );
}

export default Shop;
