import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../Theme';
import getContentValue from "../getContentValue";

const Keyword = styled('span')`
  font-size: 1rem;
  line-height: 1.6;

  ${theme.below.lg} {
    font-size: 14px;
  }

  + span {
    &::before {
      content: ', ';
    }
  }
`;

const BlogArticleKeyword = ({ keyword }) => {
  keyword = getContentValue(keyword);
  return <>{keyword && <Keyword>{keyword}</Keyword>}</>;
};

export default BlogArticleKeyword;
