import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../Theme';

const OptionItemContainer = styled('div')`
  label {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    border-top: 1px solid #eee;
    padding: 0.75rem 0;
    color: #666;
  }

  input {
    ${theme.below.md} {
      scale: 1.1;
    }
  }
`;

const ShowroomOption = ({ choice, value, label, isChecked, onChange }) => {
  return (
    <OptionItemContainer onClick={() => onChange(choice, value)}>
      <label htmlFor={choice?.choiceHeading}>
        <input
          type="radio"
          name={choice?.choiceHeading}
          value={value}
          checked={isChecked}
          onChange={() => onChange(choice, value)}
        />
        {label}
      </label>
    </OptionItemContainer>
  );
};

export default ShowroomOption;
