import React from 'react';
import { styled } from 'linaria/react';
import ContentContainer from '../Common/ContentContainer';

import 'slick-carousel/slick/slick.css';
import Slider from 'react-slick';

import { theme } from '../../Theme';
import getContentValue from "../getContentValue";

const CarouselWrapper = styled('div')`
  background-color: ${theme.colors.white};
  padding: 4rem 0;
  max-width: 100%;
  overflow: hidden;

  ${theme.below.xl} {
  }

  ${theme.below.md} {
    padding: 1.5rem 0;
  }
`;
const Container = styled(ContentContainer)`
  max-width: 96rem;
  display: block;
  padding-right: 6rem;

  ${theme.below.lg} {
    padding-right: 17vw;
  }

  ${theme.below.sm} {
    padding-right: 30vw;
  }

  .slick-slider {
    max-width: 100%;

    .slick-list {
      overflow: visible;
    }

    .slick-arrow {
      width: 40px;
      height: 40px;
      border-radius: 100px;
      background-color: rgba(255, 255, 255, 0.2);
      position: absolute;
      top: 45%;
      font-size: 0;
      color: ${theme.colors.black};
      //box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
      transition: 0.2s;
      z-index: 4;
      overflow: hidden;

      ${theme.below.lg} {
        display: none !important;
      }

      &:hover {
        //background-color: #000;
        transform: scale(1.3);
      }

      &.slick-disabled {
        //transition: opacity 0.2s, z-index 0s 0.2s;
        opacity: 0.3;
        //z-index: -1;
        transform: none !important;
        cursor: default;
      }

      &::after {
        color: ${theme.colors.black};
        font-size: 60px;
        line-height: 1;
        width: 40px;
        height: 40px;
        display: block;
        margin-top: -14px;
        //display: flex;
        //justify-content: center;
        //align-items: center;
        //position: absolute;
        //top: 50%;
        //left: 50%;
      }

      &.slick-prev {
        left: -14px;

        ${theme.above.xl} {
        }

        &::after {
          content: '‹';
        }
      }

      &.slick-next {
        right: -17px;

        ${theme.above.xl} {
        }

        &::after {
          content: '›';
        }
      }
    }

    .slick-dots {
      &[style*='block'] {
        display: flex !important;
        justify-content: center;
        align-items: center;
        margin-right: calc(-17vw + 1.25rem);

        ${theme.above.lg} {
          display: none !important;
        }

        ${theme.below.sm} {
          margin-right: calc(-30vw + 1.25rem);
        }
      }

      li {
        margin: 0 1px;

        button {
          font-size: 0;
          padding: 4px;
          background: transparent;

          &::after {
            content: '';
            display: block;
            width: 10px;
            height: 10px;
            border-radius: 20px;
            background: #ededed;
          }
        }

        &.slick-active {
          button::after {
            background: #747474;
            border-radius: 20px;
          }
        }
      }
    }
  }
`;

const Title = styled('h2')`
  width: 100%;
  padding: 0 0 2rem 2rem;
  font-size: 26px;

  ${theme.below.md} {
    padding: 0rem 0 2rem;
    font-size: 22px;
  }
`;

const ProductCarousel = ({ headingText, children }) => {
  headingText = getContentValue(headingText);

  const sliderSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <CarouselWrapper>
      <Container>
        {headingText && <Title>{headingText}</Title>}
        <Slider {...sliderSettings}>{children}</Slider>
      </Container>
    </CarouselWrapper>
  );
};

export default ProductCarousel;
