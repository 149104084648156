import t from '@jetshop/intl';
import { styled } from 'linaria/react';
import React from 'react';
import { theme } from '../../Theme';

const KeywordContainer = styled('div')`
  display: flex;
  flex-wrap: wrap;
  padding: 0 0 2rem;

  ${theme.above.sm} {
    //padding-top: 2rem;
  }
`;

const Keyword = styled('div')`
  display: flex;
  align-items: center;
  padding: 0.5rem 2rem 0.5rem 0;
  text-transform: capitalize;
  cursor: pointer;

  ${theme.below.sm} {
    font-size: 14px;
    //padding:0.5rem 0.5rem 0.5rem 0;
    // width: 33.333%;
  }

  ${theme.below.xs} {
    // width: 50%;
  }

  &:hover .inactive::after {
    background: #ddd;
  }
`;

const Marker = styled('div')`
  width: 20px;
  height: 20px;
  border-radius: 100px;
  border: 1px solid ${theme.colors.black};
  position: relative;
  padding: 2px;
  margin: 0 11px 0 0;

  &::after {
    content: '';
    display: block;
    height: 14px;
    width: 14px;
    border-radius: 100px;
    background: ${theme.colors.white};
  }

  &.active {
    &::after {
      background: ${theme.colors.black};
    }
  }
`;

const KeywordName = styled('div')``;

const KeywordFilter = ({
  allKeywords,
  activeKeywords,
  setActiveKeywords,
  setCurrentPage
}) => {
  let newKeywords = [...activeKeywords];

  const toggleActiveKeyword = keyword => {
    const keywordPos = activeKeywords.indexOf(keyword);
    if (keywordPos >= 0) {
      newKeywords.splice(keywordPos, 1);
      setActiveKeywords(newKeywords);
    } else {
      newKeywords.push(keyword);
      setActiveKeywords(newKeywords);
    }
    setCurrentPage(1);
  };

  const resetKeywords = () => {
    setActiveKeywords([]);
  };

  return (
    <KeywordContainer>
      <Keyword
        onClick={() => {
          resetKeywords();
        }}
      >
        <Marker
          className={activeKeywords.length === 0 ? 'active' : 'inactive'}
        />
        <KeywordName>{t('All')}</KeywordName>
      </Keyword>

      {allKeywords &&
        allKeywords.length > 0 &&
        allKeywords.map((keyword, index) => {
          const isActive = activeKeywords.includes(keyword);
          return (
            <Keyword
              key={keyword}
              onClick={() => {
                toggleActiveKeyword(keyword);
              }}
            >
              <Marker className={isActive ? 'active' : 'inactive'} />
              <KeywordName>{keyword}</KeywordName>
            </Keyword>
          );
        })}
    </KeywordContainer>
  );
};

export default KeywordFilter;
