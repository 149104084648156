import CategoryLink from '@jetshop/ui/CategoryLink';
import UIMenuContainer from '@jetshop/ui/Menu/MenuContainer';
import React from 'react';
import { styled } from 'linaria/react';
import SubMenuWrapper from './SubMenuWrapper';
import { theme } from '../../../Theme';
import { debounce } from 'lodash';

const Wrapper = styled('div')`
  //position: relative;
  > ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 0.625rem;
  }
`;

const MainMenuItem = styled('li')`
  height: 88px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  position: relative;

  &:hover::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    height: 30px;
    left: -100px;
    right: -100px;
    z-index: 2;
  }


  a {
    padding: 0.25rem 0.5rem;
    display: inline-block;

    &:hover {
        //color: ${theme.colors.lightgrey};

    }

    &.active {
      //color: #000;
      //font-weight: bold;    

    }

    span {
      padding: 5px 0 4px;
      display: block;
      color: ${theme.colors.white};
      font-weight: ${theme.fontWeights.medium};
      border-bottom: 1px solid transparent;

      &.show-lv2 {
        border-color: ${theme.colors.white};
      }
    }

  }
`;

const CategoryMenuContainer = ({ queryData }) => {
  const isLive = true;

  const closeNav = debounce(props => {
    props.clearActiveCategories();
  }, 500);

  return (
    <UIMenuContainer>
      {props => (
        // When mousing outside the menu, close it
        <Wrapper onMouseLeave={() => closeNav(props)}>
          <ul>
            {queryData && queryData.categories
              ? queryData.categories.map((cat, index) => {
                  return index > 5 || isLive ? (
                    <CategoryMenuItem
                      key={cat.id}
                      cat={cat}
                      debouncedClose={closeNav}
                      props={props}
                    />
                  ) : null;
                })
              : null}
          </ul>

          <SubMenuWrapper
            pose={
              props.activeCategories.length > 0 &&
              props.activeCategories[0].subcategories.length > 0
                ? 'open'
                : 'closed'
            }
            activeCategory={props.activeCategories[0]}
            closeNav={() => closeNav(props)}
            debouncedClose={closeNav}
          />
        </Wrapper>
      )}
    </UIMenuContainer>
  );
};

const CategoryMenuItem = ({ cat, debouncedClose, props }) => {
  const onMouseEnterMenu = () => {
    debouncedClose?.cancel();
    props.setActiveCategory(cat);
  };

  return (
    <MainMenuItem
      // When mousing over a menu item, set it as the active nav
      onMouseEnter={() => onMouseEnterMenu(props)}
    >
      <CategoryLink
        // When following a category link, close the menu
        onClick={props.clearActiveCategories}
        category={cat}
      >
        <span className={props.isActiveCategory(cat) ? 'show-lv2' : ''}>
          {cat.name}
        </span>
      </CategoryLink>
    </MainMenuItem>
  );
};

export default CategoryMenuContainer;
