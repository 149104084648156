import React from 'react';
import { styled } from 'linaria/react';
import ContentContainer from '../Common/ContentContainer';

import { theme } from '../../Theme';
import getContentValue from "../getContentValue";

const InfoRowWrapper = styled('div')`
  background-color: ${theme.colors.black};

  ${theme.below.xl} {
  }
  ${theme.below.md} {
  }
`;
const Container = styled(ContentContainer)`
  padding-top: 2.5rem;
  padding-bottom: 4rem;
  color: ${theme.colors.white};
  flex-wrap: wrap;

  ${theme.below.md} {
    flex-flow: column;
    padding-top: 1rem;
    padding-bottom: 3rem;
  }
`;
const Title = styled('h2')`
  width: 100%;
  padding: 1.5rem 0 0.5rem;
  font-size: 30px;

  ${theme.below.md} {
    padding: 1.5rem 0 0rem;
    font-size: 24px;
  }
`;

const InfoRow = ({ headingText, children }) => {
  headingText = getContentValue(headingText);

  return (
    <InfoRowWrapper>
      <Container>
        {headingText && <Title>{headingText}</Title>}
        {children}
      </Container>
    </InfoRowWrapper>
  );
};

export default InfoRow;
