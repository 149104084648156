import { styled } from 'linaria/react';
import React, { useState, useEffect} from 'react';
import CategoryHeader from '../CategoryHeader';
import MaxWidth from '../../Layout/MaxWidth';
import { theme } from '../../Theme';
import CategoryHeaderLoadingState from '../CategoryHeaderLoadingState';
import { useQuery } from 'react-apollo';
import systemsArticleQuery from './SystemArticleQuery.gql';
import { Above, Below, Between } from '@jetshop/ui/Breakpoints';
import SystemArticleBlurb from './SystemArticleBlurb';
import ArticlePagination from '../../ui/ArticlePagination';
import { TrackPageLoad } from '../../DataLayer/DataLayer';

const Container = styled(MaxWidth)`
  padding-top: 6rem;

  ${theme.below.sm} {
    padding-top: 2rem;
  }
`;

const CategoryWrapper = styled('div')``;

const BlurbContainer = styled('div')`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -0.5rem;

  > * {
    width: 33.33333%;

    ${theme.below.lg} {
      width: 33.33333%;
    }

    ${theme.below.md} {
      width: 50%;
    }

    ${theme.below.sm} {
      width: 100%;
    }
  }
`;

const SystemsContent = ({ systemsCatId }) => {
  const articleQuery = useQuery(systemsArticleQuery, {
    variables: {
      id: systemsCatId
    }
  });

  const [articlesPerPage, setArticlesPerPage] = useState(2);
  const [currentPage, setCurrentPage] = useState(1);
  const allArticles = articleQuery.data?.category?.subcategories;

  let currentPageArticles = !!allArticles
    ? allArticles.slice(
        (currentPage - 1) * articlesPerPage,
        currentPage * articlesPerPage
      )
    : [];

  const ArticleQtySetter = ({ articleQty }) => {
    useEffect(() => {
      setArticlesPerPage(articleQty);
    });
    return null;
  };

  return (
    <>
      <BlurbContainer>
        {currentPageArticles &&
          currentPageArticles.map((article, index) => {
            return <SystemArticleBlurb key={article.name} article={article} />;
          })}
      </BlurbContainer>
      {allArticles && (
        <ArticlePagination
          articlesPerPage={articlesPerPage}
          articleQty={allArticles.length}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )}
      <Below breakpoint={'sm'}>
        <ArticleQtySetter articleQty={12} />
      </Below>
      <Between breakpoints={['sm', 'lg']}>
        <ArticleQtySetter articleQty={18} />
      </Between>
      <Above breakpoint={'lg'}>
        <ArticleQtySetter articleQty={24} />
      </Above>
    </>
  );
};

const SystemsMainPage = ({ category, result }) => {
  if (!category) {
    return (
      <CategoryWrapper>
        <CategoryHeaderLoadingState />;
      </CategoryWrapper>
    );
  }

  return (
    <CategoryWrapper>
      <TrackPageLoad pageType={'Fläktsystem'} route={category.primaryRoute} />
      <>
        <CategoryHeader
          category={category}
          parents={result?.data?.route?.parents}
        />
        <Container>
          <SystemsContent systemsCatId={category.id} />
        </Container>
      </>
    </CategoryWrapper>
  );
};

export default SystemsMainPage;
