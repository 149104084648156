import useDecrementQuantity from '@jetshop/core/components/Mutation/useDecrementQuantity';
import useIncrementQuantity from '@jetshop/core/components/Mutation/useIncrementQuantity';
import useRemoveFromCart from '@jetshop/core/components/Mutation/useRemoveFromCart';
import getCartItemVariant from '@jetshop/core/helpers/getCartItemVariant';
import Image from '@jetshop/ui/Image';
import { Price } from '@jetshop/ui/Price';
import { ReactComponent as Cross } from '@jetshop/ui/svg/Cross.svg';
import React from 'react';
import { styled } from 'linaria/react';
import { Link } from 'react-router-dom';
import { FlyoutTrigger } from '@jetshop/ui/Modal/Flyout';
import removeFromCartMutation from './removeFromCart.gql';
import incrementQuantityMutation from './incrementQuantity.gql';
import decrementQuantityMutation from './decrementQuantity.gql';
import cartQuery from './CartQuery.gql';
import { theme } from '../Theme';
import t from '@jetshop/intl';

import {
  IncreaseClickTracker,
  DecreaseClickTracker,
  RemoveClickTracker
} from '../DataLayer/DataLayer';
import PriceSection from '../ProductPage/PriceSection';

const Wrapper = styled('div')`
  display: flex;
  background: white;
  margin-bottom: 0.5rem;
  border-top: 1px solid ${theme.colors.lightgrey};
  padding: 0.5rem 0 0;

  > :first-child {
    flex: 1 1 20%;
  }

  > :last-child {
    flex: 1 1 75%;
    padding: 0.5rem;
  }
`;

const ProductName = styled('div')`
  font-size: 0.875rem;
  font-weight: normal;

  h2,
  a {
    color: ${theme.colors.black};
  }

  .attribute {
    display: block;
    font-weight: ${theme.fontWeights.light};
  }
`;

const CommentsWrapper = styled('div')``;

const Comment = styled('div')`
  padding: 3px 0;
  //font-style: italic;
  font-weight: ${theme.fontWeights.light};
`;

const ArticleNumber = styled('span')`
  line-height: 15px;
  font-size: 12px;
  color: ${theme.colors.grey};
  display: inline-block;
  margin: 2px 0;
`;

const ProductDetail = styled('section')`
  display: grid;
  grid-auto-columns: 1fr 1fr 1fr 1fr;
  grid-template-areas: 'topleft topleft topleft topright' 'bottomleft bottomleft bottomright bottomright';
`;

const ItemDetails = styled('div')`
  grid-area: topleft;
`;

const LinePrice = styled(PriceSection)`
  .price:first-of-type {
    color: black !important;
  }
  margin-top: 1rem;
  grid-area: bottomright;
  font-weight: 600;
  text-align: right;
  align-items: end;
  flex-direction: column;
  justify-content: flex-end;
`;

const LinePrice1 = styled(Price)`
  margin-top: 1rem;
  grid-area: bottomright;
  font-weight: 600;
  text-align: right;
`;

const AdjustQty = styled('div')`
  margin-top: 0.75rem;
  display: flex;
  grid-area: bottomleft;
  align-items: center;
  /* Plus/minus buttons */

  button {
    background: ${theme.colors.black};
    color: ${theme.colors.white};
    border-radius: 50%;
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;

    &[disabled=''] {
      opacity: 0.5;
    }

    :focus {
      outline: none;
    }
  }

  /* Count */

  span {
    margin: 0 0.5rem;
    display: inline-block;
    font-family: ${theme.fonts.secondary};
  }
`;

const RemoveItem = styled('div')`
  grid-area: topright;
  text-align: right;

  button {
    padding: 0;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    outline: none;
    border: none;
    background: #8f8f8f;
    margin-left: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  svg {
    height: 8px;
    width: 8px;
  }
`;

const CartItem = ({ item, className = '', isConfigItem }) => {
  const {
    isVariant,
    variantImage,
    variantValues,
    hasVariantImage
  } = getCartItemVariant(item);

  const { incrementQuantity } = useIncrementQuantity({
    incrementQuantityMutation,
    cartQuery
  });

  const { decrementQuantity } = useDecrementQuantity({
    decrementQuantityMutation,
    cartQuery
  });

  const { removeFromCart } = useRemoveFromCart({
    removeFromCartMutation,
    cartQuery
  });

  const variantNamesValues = variantValues.map(
    (value, index) => `${item.variantOptionNames[index]}: ${value}`
  );

  const isConfigurationItem = isConfigItem;
  let classNames = className + isConfigurationItem ? ' config-item' : '';

  return (
    <Wrapper className={classNames}>
      {!isConfigurationItem && (
        <div>
          {item.product.images.length > 0 && (
            <Image
              aspect="1:1"
              sizes="5rem"
              src={
                hasVariantImage ? variantImage.url : item.product.images[0].url
              }
              alt={
                hasVariantImage ? variantImage.alt : item.product.images[0].alt
              }
              quality={80}
            />
          )}
        </div>
      )}
      <ProductDetail>
        <ItemDetails className={'item-details'}>
          <FlyoutTrigger id="cart-flyout">
            {flyout => (
              <ProductName onClick={flyout.hideTarget}>
                {!isConfigurationItem ? (
                  <Link to={item.product.primaryRoute.path}>
                    <h2 data-testid="item-name">{item.product.name}</h2>
                  </Link>
                ) : (
                  <h3 data-testid="item-name">{item.product.name}</h3>
                )}
                {isVariant && (
                  <ul
                    style={{
                      marginTop: '3px'
                    }}
                  >
                    {variantNamesValues.map((variantOptionNameValue, index) => (
                      <li className="attribute" key={index}>
                        {variantOptionNameValue}
                      </li>
                    ))}
                  </ul>
                )}
                {item.configurations.length > 0 && (
                  <ul
                    style={{
                      marginTop: '0'
                    }}
                  >
                    {item.configurations.map(({ option: { name } }, index) => (
                      <li className="attribute" key={index}>
                        {name}
                      </li>
                    ))}
                  </ul>
                )}

                {item.customerComments.length ? (
                  <CommentsWrapper>
                    {item.customerComments.map((comment, index) => {
                      let commentClass = comment.name.match(/del|osa/i)
                        ? 'config-part'
                        : '';
                      commentClass += comment.name.match(/id/i)
                        ? ' config-id'
                        : '';
                      return (
                        <Comment className={commentClass} key={comment.name}>
                          <span>{comment.name}: </span>
                          <span>{comment.value}</span>
                        </Comment>
                      );
                    })}
                  </CommentsWrapper>
                ) : null}

                <ArticleNumber className={'article-number'}>
                  {t('Article number')}: {item.product.articleNumber}
                </ArticleNumber>
              </ProductName>
            )}
          </FlyoutTrigger>
        </ItemDetails>

        {!isConfigurationItem && (
          <>
            <RemoveItem>
              <RemoveClickTracker item={item}>
                <button
                  onClick={() =>
                    removeFromCart({ itemId: item.id, product: item.product })
                  }
                  data-testid="remove-from-cart"
                >
                  <Cross />
                </button>
              </RemoveClickTracker>
            </RemoveItem>

            <AdjustQty>
              <DecreaseClickTracker item={item}>
                <button
                  data-testid="decrement-quantity"
                  disabled={item.quantity === 1}
                  onClick={() =>
                    item.quantity !== 1 &&
                    decrementQuantity({ itemId: item.id })
                  }
                >
                  -
                </button>
              </DecreaseClickTracker>
              <span data-testid="item-quantity">{item.quantity}</span>
              <IncreaseClickTracker item={item}>
                <button
                  data-testid="increment-quantity"
                  onClick={() => incrementQuantity({ itemId: item.id })}
                >
                  +
                </button>
              </IncreaseClickTracker>
            </AdjustQty>
          </>
        )}
        {/* <LinePrice1
          className={'price-outer'}
          data-testid="item-price"
          price={item.total}
          previousPrice={item.previousTotal}
        /> */}
        <LinePrice
          className="price-outer"
          price={item.total}
          previousPrice={item.previousTotal}
        />
      </ProductDetail>
    </Wrapper>
  );
};

export default CartItem;
