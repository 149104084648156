import ChannelContext from '@jetshop/core/components/ChannelContext';
import { FlyoutTarget, FlyoutTrigger } from '@jetshop/ui/Modal/Flyout';
import React, { Component, Fragment } from 'react';
import Selector from './Selector';
import { styled } from 'linaria/react';
import { ReactComponent as Caret } from '../../../../svg/Caret.svg';
import { theme } from '../../../Theme';

const Flyout = styled('div')`
  background-color: ${theme.colors.black};
  position: absolute;
  z-index: 100;
  top: 40px;
  right: 0;
  color: ${theme.colors.white};
`;

const CountryFlag = styled('img')`
  height: 24px;
  margin-right: 5px;
`;

export default class HeaderChannelSelector extends Component {
  render() {
    return (
      <ChannelContext.Consumer>
        {({ channels, selectedChannel, updateChannel }) => {
          return (
            <Fragment>
              <FlyoutTrigger id="channel-selector">
                {({ showTarget, hideTarget, isOpen }) => (
                  <button
                    data-testid="channel-selector-button"
                    onClick={isOpen ? hideTarget : showTarget}
                    style={{ background: 'transparent' }}
                  >
                    <CountryFlag
                      src={`https://countryflags.jetshop.io/${selectedChannel.country.code}/flat/64.png`}
                      alt="Country Flag"
                    />
                    <Caret />
                  </button>
                )}
              </FlyoutTrigger>
              <FlyoutTarget id="channel-selector">
                {({ isOpen, hideTarget }) => (
                  <Flyout>
                    <Selector
                      channels={channels}
                      hideTarget={hideTarget}
                      selectedChannel={selectedChannel}
                      updateChannel={updateChannel}
                    />
                  </Flyout>
                )}
              </FlyoutTarget>
            </Fragment>
          );
        }}
      </ChannelContext.Consumer>
    );
  }
}
