import React from 'react';
import pagesQuery from './PagesQuery.gql';
import ContentPageLink from '@jetshop/ui/ContentPageLink';
import StoreTitleQuery from './StoreTitleQuery.gql';
import { useQuery } from '@apollo/react-hooks';

function FooterLinks() {
  const { data } = useQuery(pagesQuery);
  const { data: storeTitleData, loading: storeTitleLoading } = useQuery(
    StoreTitleQuery
  );

  const pages = data?.pages;
  const storeTitle = storeTitleData?.startPage?.head.title;

  return pages ? (
    <ul className="footer-links">
      {pages.map(page => (
        <li key={page.id}>
          <ContentPageLink page={page}>{page.name}</ContentPageLink>
        </li>
      ))}
    </ul>
  ) : null;
}

export default FooterLinks;
