import ChannelContext from '@jetshop/core/components/ChannelContext';
import t from '@jetshop/intl';
import {Above} from '@jetshop/ui/Breakpoints';
import {FlyoutTarget, FlyoutTrigger} from '@jetshop/ui/Modal/Flyout';
import {ReactComponent as Carrot} from '@jetshop/ui/svg/Carrot.svg';
import React, {useContext, useState} from 'react';
import {styled} from 'linaria/react';
import Button from '../../../ui/Button';
import CloseButton from '../../../ui/CloseButton';
import {useChannelBanner} from '@jetshop/core/hooks/useChannelBanner';
import {theme} from '../../../Theme';
import Selector from "../ChannelSelector/Selector";
import {css} from 'linaria';


const ChannelBannerWrapper = styled('div')`
  display: none;

  &.openBanner {
    display: flex;
  }

  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 200;


  .click-bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }



`;

const BannerContainer = styled('div')`

  min-height: 300px;
  max-width: 500px;
  padding: 2rem;
  background-color: ${theme.colors.white};
  position: relative;
  z-index: 111;
  color: ${theme.colors.black};
  //border: 1px solid #fff;
  box-shadow: 0 0 1rem 0.5rem rgb(0, 0, 0, 0.5);
  


  .close-button {
    background: ${theme.colors.black};
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    border-radius: 10rem;
    padding: 0.5rem;

    svg {
      fill: #fff;

      path {
        fill: #fff;
      }
    }
  }

  ${theme.above.md} {
    .close-button {
    }
  }

  ${theme.below.sm} {
    width: 80vw;
    min-height: 0;
  }


`;

const BannerContent = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  text-align: center;

  > * {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .banner-text {
    //margin-right: 24px;
    line-height: 1.5;
  }

  .light-text {
    //color: #a5a5a5;
    width: 100%;
    margin: 0 20px;
    white-space: nowrap;

    &::before,
    &::after {
      background-color: #cccccc;
      content: '';
      display: inline-block;
      height: 1px;
      position: relative;
      vertical-align: middle;
      width: 45%;
    }


    &:before {
      right: 1rem;
      //margin-left: -50%;
    }

    &:after {
      left: 1rem;
      //margin-right: -50%;
    }
  }

  .selector-wrapper {
    position: relative;
  }

  svg.carrot-icon {
    fill: #fff;
    width: 9px;
    margin-right: 0;
  }

  .carrot-icon[data-isopen='true'] {
    transform: rotate(180deg);
  }

  .selector-flyout-wrapper {
    position: absolute;
  }

  ${theme.below.md} {
    flex-direction: column;

    .banner-text {
      width: 100%;
      margin-right: 0;
      margin-bottom: 16px;
      text-align: center;
      font-size: 0.875rem;
    }

    .light-text {
      margin: 13px auto;
      text-align: center;
      overflow: hidden;



    }

    .dropdown-selector {
      height: calc(100vh - 229px);
      overflow-y: scroll;
    }

    .selector-wrapper {
      width: 100%;
    }
  }


  ${theme.below.sm} {
    > * {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }

  }
`;

const ChannelButton = styled(Button)`
  height: 46px;
  width: 200px;
  text-transform: none;
  border-radius: 40rem;
  font-size: ${theme.fontSizes[1]};

  ${theme.below.md} {
    width: 100%;
  }
`;

const SelectorButton = styled(Button)`
  height: 46px;
  width: 200px;
  padding: 0 16px;
  text-transform: capitalize;
  background-color: ${theme.colors.black};
  //border: 1px solid #e1e1e1;
  border-radius: 4px;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    font-size: ${theme.fontSizes[1]};
  }

  &:hover {
    //background-color: #ffffff;
    //color: #4f4f4f;
  }

  ${theme.below.md} {
    width: 100%;
  }
`;


const selectorStyles = css`
  background: ${theme.colors.black};
  border: 0;
  padding-top: 0;

  h1 {
    display: none;
  }

  .country {
    color: #fff;
    height: 40px;
    margin: 1rem 0;
  }
`;


const Flyout = ({children}) => (
  <div
    style={{
      position: 'absolute',
      backgroundColor: 'white',
      zIndex: '999',
      width: '200px'
    }}
  >
    {children}
  </div>
);

const ChannelBanner = () => {
  const {
    channels,
    recommendedChannel,
    selectedChannel,
    updateChannel
  } = useContext(ChannelContext);

  const {bannerIsOpen, hideTheBanner} = useChannelBanner();

  const [dropdownSelector, setDropdownSelector] = useState(false);
  const toggleDropdownSelector = () => {
    dropdownSelector ? setDropdownSelector(false) : setDropdownSelector(true);
  };

  // Return early if openBanner is false or we have no recommended channel


  if (!bannerIsOpen || !recommendedChannel) return null;

  return (
    <ChannelBannerWrapper className={bannerIsOpen && 'openBanner'}>
      <div className='click-bg' onClick={hideTheBanner} />
      <BannerContainer>
        <BannerContent>
          <div className="banner-text">
            {t(
              'It looks like you are visiting us from {country}. Do you want to visit our {country} site?',
              {
                country: recommendedChannel.country.name
              }
            )}
          </div>
          <ChannelButton
            onClick={() => {
              updateChannel(recommendedChannel);
              hideTheBanner();
            }}
          >
            {t('Go to {country}', {
              country: recommendedChannel.country.name
            })}
          </ChannelButton>
          <div className="light-text">{t('or')}</div>
          <Above breakpoint="md">
            {matches =>
              matches ? (
                <div className="selector-wrapper">
                  <FlyoutTrigger id="recommended-channel-selector">
                    {({showTarget, hideTarget, isOpen}) => (
                      <SelectorButton
                        onClick={isOpen ? hideTarget : showTarget}
                      >
                        <span>{t('Choose country')}</span>
                        <Carrot className="carrot-icon" data-isopen={isOpen}/>
                      </SelectorButton>
                    )}
                  </FlyoutTrigger>
                  <FlyoutTarget id="recommended-channel-selector">
                    {({isOpen, hideTarget}) => (
                      <Flyout>
                        <Selector
                          channels={channels}
                          selectedChannel={selectedChannel}
                          updateChannel={updateChannel}
                          hideTarget={hideTarget}
                          importedStyles={selectorStyles}

                        />
                      </Flyout>
                    )}
                  </FlyoutTarget>
                </div>
              ) : (
                <div className="selector-wrapper">
                  <SelectorButton onClick={toggleDropdownSelector}>
                    <span>{t('Choose country')}</span>
                    <Carrot
                      className="carrot-icon"
                      data-isopen={dropdownSelector}
                    />
                  </SelectorButton>
                  {dropdownSelector && (
                    <div className="dropdown-selector">
                      <Selector
                        channels={channels}
                        hideTarget={() => setDropdownSelector(false)}
                        selectedChannel={selectedChannel}
                        updateChannel={updateChannel}
                        importedStyles={selectorStyles}
                      />
                    </div>
                  )}
                </div>
              )
            }
          </Above>
        </BannerContent>

        <CloseButton className="close-button" onClick={hideTheBanner}/>
      </BannerContainer>
    </ChannelBannerWrapper>
  );
};

export default ChannelBanner;
