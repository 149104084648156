import ChannelContext from '@jetshop/core/components/ChannelContext';
import { ModalTrigger } from '@jetshop/ui/Modal/ModalTrigger';
import React, { useContext } from 'react';
import { css } from 'linaria';
import Selector from './Selector';
import { styled } from 'linaria/react';
import {theme} from "../../../Theme";
import MiniSelector from "@jetshop/ui/ChannelSelector/MiniSelector";
import {ReactComponent as Caret} from "../../../../svg/Caret.svg";

const Button = styled('button')`
  all: unset;
  cursor: pointer;
  background: ${theme.colors.black} !important;
  border-radius: 50rem;
  transition: 0.1s;
  padding: 0.5rem 1rem 0.5rem 1.25rem;
  text-align: center;
  min-width: 3rem;

  &:hover {
    background: #ccc;
  }

  img {
    height: 24px;
    margin-right: 5px;    
  }
  
  svg {
    fill: #fff !important;
  }
`;

const modalStyles = css`
  left: 0;
  right: 0;
  top: 0;
  background: ${theme.colors.black};
  padding: 1rem;
  outline: none;
  z-index: 100;
  position: absolute;
`;

const importedStyles = css`
  max-width: none;
  
  .country {
    color: #fff;
    height: 40px;
    margin: 1rem 0;
  }
`;




const ChannelSelectorModal = props => {
  const { selectedChannel, channels, updateChannel } = useContext(
    ChannelContext
  );

  return (
    <ModalTrigger
      modalStyles={modalStyles}
      target={props => (
        <Selector
          channels={channels}
          selectedChannel={selectedChannel}
          updateChannel={updateChannel}
          type={MiniSelector}
          importedStyles={importedStyles}
          {...props}
        />
      )}
    >
      {({ showTarget }) => (
        <Button onClick={showTarget} {...props}>
          <img
            src={`https://countryflags.jetshop.io/${selectedChannel.country.code}/flat/32.png`}
            alt="Country Flag"
          />

          <Caret />

        </Button>
      )}
    </ModalTrigger>
  );
};

export default ChannelSelectorModal;
