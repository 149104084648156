import React from 'react';
import { styled } from 'linaria/react';
import ContentContainer from '../Common/ContentContainer';

import { theme } from '../../Theme';

const Wrapper = styled('div')`
  background-color: ${theme.colors.white};

  + .image-link-row > * {
    padding-top: 0;
  }
  ${theme.below.xl} {
  }
  ${theme.below.md} {
  }
`;
const Container = styled(ContentContainer)`
  max-width: 120rem;

  ${theme.below.md} {
  }
`;

const ImageLinkRow = ({ children }) => {
  return (
    <Wrapper className="image-link-row">
      <Container className={'items-' + children.length}>{children}</Container>
    </Wrapper>
  );
};

export default ImageLinkRow;
