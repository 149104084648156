import React from 'react';
import {styled} from 'linaria/react';
import {theme} from '../../Theme';
import t from '@jetshop/intl';

import NewsletterField from '../../Newsletter/NewsletterField';

const Wrapper = styled('div')`
  width: 50%;
  display: flex;
  justify-content: flex-end;
  flex-shrink: 0;
  background: ${theme.colors.background};
  color: ${theme.colors.black};
  height: 350px;
  align-items: center;


  ${theme.below.lg} {
    width: 100%;
    height: auto;
    justify-content: center;
  }
  
  
`;

const Inner = styled('div')`
  max-width: 45rem;
  padding: 2rem;
  width: 100%;

  ${theme.below.md} {
    padding: 4rem 2rem;
  }

  ${theme.below.sm} {
    padding: 3rem 1.25rem;
  }
  

  h3 {
    font-size: 18px;

    ${theme.below.sm} {
      font-size: 16px;
    }
    
  }

  h2 {
    font-size: 26px;
    line-height: 1.3;
    max-width: 322px;
    margin-top: 1rem;

    ${theme.below.sm} {
      font-size: 22px;
    }

  }

`;


const NewsletterSignup = () => (
  <Wrapper>
    <Inner>
      <h3>{t('Newsletter')}</h3>
      <h2>{t('Follow us for')}</h2>
      <NewsletterField ></NewsletterField>
    </Inner>

  </Wrapper>
);

export default NewsletterSignup;
