import React from 'react';
import BigHero from './Root/BigHero';
import InfoRow from './Root/InfoRow';
import InfoItem from './Component/InfoItem';
import CategoryLinkRow from './Root/CategoryLinkRow';
import CategoryLinkItem from './Component/CategoryLinkItem';
import ImageLinkRow from './Root/ImageLinkRow';
import ImageLinkItem from './Component/ImageLinkItem';
import VideoRow from './Root/VideoRow';
import ProductCarousel from './Root/ProductCarousel';
import ProductCarouselItem from './Component/ProductCarouselItem';
import SingleH2Heading from './Root/SingleH2Heading';
import SingleH3Heading from './Root/SingleH3Heading';
import SingleParagraph from './Root/SingleParagraph';
import SingleHtmlBlock from './Root/SingleHtmlBlock';
import SingleImage from './Root/SingleImage';
import BlogArticleKeyword from './Component/BlogArticleKeyword';
import BlogArticleInfo from './Root/BlogArticleInfo';
import ArticleVideo from './Root/ArticleVideo';
import HeadingLinkItem from './Component/HeadingLinkItem';
import HeadingLinkList from './Root/HeadingLinkList';
import SingleRawHtmlBlock from './Root/SingleRawHtmlBlock';
import { Video } from './Root/Video';
import UspList from './Root/UspList';
import UspItem from './Component/UspItem';
import Banner from './Root/Banner';

const contentComponents = {
  'BIG HERO': BigHero,
  'INFO ROW': InfoRow,
  'INFO ITEM': InfoItem,
  'IMAGE LINK ROW': ImageLinkRow,
  'IMAGE LINK ITEM': ImageLinkItem,
  'VIDEO ROW': VideoRow,
  'PRODUCT CAROUSEL': ProductCarousel,
  'PRODUCT CAROUSEL ITEM': ProductCarouselItem,
  'CATEGORY LINK ROW': CategoryLinkRow,
  'CATEGORY LINK ITEM': CategoryLinkItem,
  'SINGLE H2 HEADING': SingleH2Heading,
  'SINGLE H3 HEADING': SingleH3Heading,
  'SINGLE PARAGRAPH': SingleParagraph,
  'SINGLE HTML BLOCK': SingleHtmlBlock,
  'SINGLE RAW HTML BLOCK': SingleRawHtmlBlock,
  'SINGLE IMAGE': SingleImage,
  'BLOG ARTICLE INFO': BlogArticleInfo,
  'BLOG ARTICLE KEYWORD': BlogArticleKeyword,
  'ARTICLE VIDEO': ArticleVideo,
  'HEADING LINK ITEM': HeadingLinkItem,
  'HEADING LINK LIST': HeadingLinkList,
  VIDEO: Video,
  'USP LIST': UspList,
  'USP ITEM': UspItem,
  Banner: Banner
};

export default contentComponents;
