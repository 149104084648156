import { css } from 'linaria';

import DaxRegularWoff from './Dax-Regular.woff';
import DaxRegularWoff2 from './Dax-Regular.woff2';

import DaxRegularItalicWoff from './Dax-Regular-Italic.woff';
import DaxRegularItalicWoff2 from './Dax-Regular-Italic.woff2';

import DaxLightWoff from './Dax-Light.woff';
import DaxLightWoff2 from './Dax-Light.woff2';

import DaxLightItalicWoff from './Dax-Light-Italic.woff';
import DaxLightItalicWoff2 from './Dax-Light-Italic.woff2';

import DaxMediumWoff from './Dax-Medium.woff';
import DaxMediumWoff2 from './Dax-Medium.woff2';

import DaxMediumItalicWoff from './Dax-Regular.woff';
import DaxMediumItalicWoff2 from './Dax-Regular.woff2';

import DaxBoldWoff from './Dax-Bold.woff';
import DaxBoldWoff2 from './Dax-Bold.woff2';

import BebasNeueWoff from './BebasNeue-Regular.woff';
import BebasNeueWoff2 from './BebasNeue-Regular.woff2';

import ProximaNovaBoldWoff from './ProximaNova-Bold.woff';
import ProximaNovaBoldWoff2 from './ProximaNova-Bold.woff2';

import ProximaNovaLightWoff from './ProximaNova-Light.woff';
import ProximaNovaLightWoff2 from './ProximaNova-Light.woff2';

import ProximaNovaMediumWoff from './ProximaNova-Medium.woff';
import ProximaNovaMediumWoff2 from './ProximaNova-Medium.woff2';

import ProximaNovaRegularWoff from './ProximaNova-Regular.woff';
import ProximaNovaRegularWoff2 from './ProximaNova-Regular.woff2';

import ProximaNovaSemiboldWoff from './ProximaNova-Semibold.woff';
import ProximaNovaSemiboldWoff2 from './ProximaNova-Semibold.woff2';

export default function loadCss() {
  return css`
    :global(html) {
      /* Regular font */
      @font-face {
        font-family: 'Dax';
        src: local('Dax Regular'), local('DaxRegular'),
          url(${DaxRegularWoff2}) format('woff2'),
          url(${DaxRegularWoff}) format('woff');
        font-weight: 400;
        font-style: normal;
        font-display: swap;
      }

      @font-face {
        font-family: 'Dax';
        src: local('Dax Regular Italic'), local('DaxRegular-Italic'),
          url(${DaxRegularItalicWoff2}) format('woff2'),
          url(${DaxRegularItalicWoff}) format('woff');
        font-weight: 300;
        font-style: italic;
        font-display: swap;
      }

      @font-face {
        font-family: 'Dax';
        src: local('Dax Light'), local('Dax-Light'),
          url(${DaxLightWoff2}) format('woff2'),
          url(${DaxLightWoff}) format('woff');
        font-weight: 300;
        font-style: normal;
        font-display: swap;
      }

      @font-face {
        font-family: 'Dax';
        src: local('Dax Light Italic'), local('DaxLight-Italic'),
          url(${DaxLightItalicWoff2}) format('woff2'),
          url(${DaxLightItalicWoff}) format('woff');
        font-weight: 300;
        font-style: italic;
        font-display: swap;
      }

      @font-face {
        font-family: 'Dax';
        src: local('Dax Medium'), local('DaxMedium'),
          url(${DaxMediumWoff2}) format('woff2'),
          url(${DaxMediumWoff}) format('woff');
        font-weight: 600;
        font-style: normal;
        font-display: swap;
      }

      @font-face {
        font-family: 'Dax';
        src: local('Dax Medium Italic'), local('DaxMedium-Italic'),
          url(${DaxMediumItalicWoff2}) format('woff2'),
          url(${DaxMediumItalicWoff}) format('woff');
        font-weight: 600;
        font-style: italic;
        font-display: swap;
      }

      @font-face {
        font-family: 'Dax';
        src: local('Dax Bold'), local('DaxBold'),
          url(${DaxBoldWoff2}) format('woff2'),
          url(${DaxBoldWoff}) format('woff');
        font-weight: 700;
        font-style: normal;
        font-display: swap;
      }

      @font-face {
        font-family: 'Proxima Nova';
        src: local('Proxima Nova Light'), local('ProximaNovaLight'),
          url(${ProximaNovaLightWoff}) format('woff2'),
          url(${ProximaNovaLightWoff2}) format('woff');
        font-weight: 300;
        font-style: normal;
        font-display: swap;
      }

      @font-face {
        font-family: 'Proxima Nova';
        src: local('Proxima Nova Regular'), local('ProximaNovaRegular'),
          url(${ProximaNovaRegularWoff}) format('woff2'),
          url(${ProximaNovaRegularWoff2}) format('woff');
        font-weight: 400;
        font-style: normal;
        font-display: swap;
      }

      @font-face {
        font-family: 'Proxima Nova';
        src: local('Proxima Nova Medium'), local('ProximaNovaMedium'),
          url(${ProximaNovaMediumWoff}) format('woff2'),
          url(${ProximaNovaMediumWoff2}) format('woff');
        font-weight: 500;
        font-style: normal;
        font-display: swap;
      }

      @font-face {
        font-family: 'Proxima Nova';
        src: local('Proxima Nova Semibold'), local('ProximaNovaSemibold'),
          url(${ProximaNovaSemiboldWoff}) format('woff2'),
          url(${ProximaNovaSemiboldWoff2}) format('woff');
        font-weight: 600;
        font-style: normal;
        font-display: swap;
      }

      @font-face {
        font-family: 'Proxima Nova';
        src: local('Proxima Nova Bold'), local('ProximaNovaBold'),
          url(${ProximaNovaBoldWoff}) format('woff2'),
          url(${ProximaNovaBoldWoff2}) format('woff');
        font-weight: 700;
        font-style: normal;
        font-display: swap;
      }

      @font-face {
        font-family: 'Bebas Neue';
        src: local('Bebas Neue'), local('BebasNeue'),
          url(${BebasNeueWoff2}) format('woff2'),
          url(${BebasNeueWoff}) format('woff');
        font-weight: 400;
        font-style: normal;
        font-display: swap;
      }

      &.dax-ready body {
        font-family: 'Proxima Nova', sans-serif;
        line-height: 1;
        letter-spacing: 0;
        word-spacing: 0;
        font-weight: normal;
      }
    }
  `;
}
