import React from 'react';
import { styled } from 'linaria/react';
import { css } from 'linaria';
import Image from '@jetshop/ui/Image/Image';
import { TrendLink } from '../../ui/Button';
import ContentContainer from '../Common/ContentContainer';
import { theme } from '../../Theme';
import getContentValue from '../getContentValue';

const Wrapper = styled('div')`
  position: relative;
  height: ${props => `${props.height}px`};
  ${theme.below.md} {
    height: ${props =>
      `${props.mobileHeight ? props.mobileHeight : props.height}px`};
  }

  video {
    width: 100%;
    object-fit: cover;
    height: 100%;
  }
`;

const Overlay = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  justify-content: center;
  background-color: rgba(31, 31, 31, 0.4);
`;

const Container = styled(ContentContainer)`
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: ${props => props.contentPosition ?? 'center'};
  color: white;
  height: 100%;
  text-align: left;

  .content-block p {
    max-width: 600px;
  }

  .banner-title {
    font-size: ${props => props.titleSize}px;
    margin-bottom: 18px;
    ${theme.below.md} {
      font-size: ${props =>
        props.mobileTitleSize ? props.mobileTitleSize : props.titleSize}px;
      margin-bottom: 12px;
    }
  }
`;

const Description = styled('p')`
  font-size: 16px;
  font-weight: ${theme.fontWeights.medium};
  margin-bottom: 30px;
  line-height: 1.45em;

  ${theme.below.md} {
    width: 100%;
    font-size: 14px;
  }
`;
//#0080FF80
const StyledButton = styled(TrendLink)`
  background: ${props => `${props.background}75`};
  color: ${props => props.textColor};
  width: fit-content;
  transition: all 0.5s
  &:hover {
    background: ${props => `${props.background}`};
  }
`;

function TitleRenderer({ level, text }) {
  switch (level) {
    case 'h1':
      return <h1 className="banner-title">{text}</h1>;
    case 'h2':
      return <h2 className="banner-title">{text}</h2>;
    case 'h3':
      return <h3 className="banner-title">{text}</h3>;
    case 'h4':
      return <h4 className="banner-title">{text}</h4>;
    case 'h5':
      return <h5 className="banner-title">{text}</h5>;
    case 'h6':
      return <h6 className="banner-title">{text}</h6>;
    default:
      return <></>;
  }
}

//https://res.cloudinary.com/dragster/video/upload/v1620196874/Video/Hemsida-1920x700-10sek.mp4

const Banner = ({
  headingText,
  bodyText,
  buttonText,
  buttonLink,
  image,
  videoUrl,
  desktopHeight,
  mobileHeight,
  horizontalPosition,
  titleLevel,
  titleSizeDesktop,
  titleSizeMobile,
  buttonBackground,
  buttonTextColor,
  hideButton
}) => {
  headingText = getContentValue(headingText);
  bodyText = getContentValue(bodyText);
  buttonText = getContentValue(buttonText);
  buttonLink = getContentValue(buttonLink);
  image = getContentValue(image);
  videoUrl = getContentValue(videoUrl);
  desktopHeight = getContentValue(desktopHeight);
  mobileHeight = getContentValue(mobileHeight);
  horizontalPosition = getContentValue(horizontalPosition);
  titleLevel = getContentValue(titleLevel);
  titleSizeDesktop = getContentValue(titleSizeDesktop);
  titleSizeMobile = getContentValue(titleSizeMobile);
  buttonBackground = getContentValue(buttonBackground);
  buttonTextColor = getContentValue(buttonTextColor);
  hideButton = hideButton?.value?.value ?? false;

  return (
    <Wrapper height={Number(desktopHeight)} mobileHeight={Number(mobileHeight)}>
      {videoUrl ? (
        <>
          <video
            playsInline="playsInline"
            autoPlay="autoplay"
            muted="muted"
            loop="loop"
          >
            <source src={videoUrl} type="video/mp4" />
          </video>
          <Overlay>
            <Container
              contentPosition={horizontalPosition}
              titleSize={titleSizeDesktop}
              mobileTitleSize={titleSizeMobile}
            >
              <div className="content-block">
                {headingText && (
                  <TitleRenderer level={titleLevel} text={headingText} />
                )}
                {bodyText && <Description>{bodyText}</Description>}
                {buttonText && buttonLink && !hideButton && (
                  <StyledButton
                    to={buttonLink}
                    background={buttonBackground}
                    textColor={buttonTextColor}
                  >
                    {buttonText}
                  </StyledButton>
                )}
              </div>
            </Container>
          </Overlay>
        </>
      ) : (
        <>
          <Image
            fillAvailableSpace={true}
            src={image}
            sizes={[1, 1, 1, 1]}
          ></Image>
          <Overlay>
            <Container
              contentPosition={horizontalPosition}
              titleSize={titleSizeDesktop}
              mobileTitleSize={titleSizeMobile}
            >
              <div className="content-block">
                {headingText && (
                  <TitleRenderer level={titleLevel} text={headingText} />
                )}
                {bodyText && <Description>{bodyText}</Description>}
                {buttonText && buttonLink && !hideButton && (
                  <StyledButton
                    to={buttonLink}
                    background={buttonBackground}
                    textColor={buttonTextColor}
                  >
                    {buttonText}
                  </StyledButton>
                )}
              </div>
            </Container>
          </Overlay>
        </>
      )}
    </Wrapper>
  );
};

export default Banner;
