import { styled } from 'linaria/react';
import React, { useContext } from 'react';
import CategoryHeader from '../CategoryPage/CategoryHeader';
import MaxWidth from '../Layout/MaxWidth';
import { theme } from '../Theme';
import CategoryHeaderLoadingState from './CategoryHeaderLoadingState';
import { TrackPageLoad } from '../DataLayer/DataLayer';
import Spinner from '../ui/Spinner';
import ChannelContext from '@jetshop/core/components/ChannelContext';

const Container = styled(MaxWidth)`
  padding-top: 2rem;
  padding-right: 0;
  padding-left: 0;
  max-width: 75rem;

  ${theme.above.sm} {
    padding-top: 2rem;
  }
`;

const CategoryWrapper = styled('div')`
  h1 {
    //opacity: 0;
    //position: absolute;
    //width: 0;
    //height: 0;
  }
`;

const IframeContainer = styled('div')`
  padding: 0 1rem;
  position: relative;

  ${theme.below.sm} {
    padding: 0 1rem;
  }

  iframe {
    display: block;
    width: 100%;
    height: 800px;
    position: relative;
    z-index: 2;

    ${theme.below.lg} {
      //height: 2400px;
    }
  }
`;

const IframeSpinner = styled(Spinner)`
  position: absolute;
  top: 5rem;
  left: 49%;
  z-index: 1;
`;

const Category = ({ category, result }) => {
  const {
    selectedChannel: {
      language: { culture }
    }
  } = useContext(ChannelContext);

  const iframes = {
    'sv-SE': 'https://fjaraskupan.hemsida.eu/bestall-produktkatalog-jetshop/',
    'nb-NO':
      'https://fjaraskupan.hemsida.eu/bestall-produktkatalog-jetshop-no/',
    'fi-FI': 'https://fjaraskupan.hemsida.eu/bestall-produktkatalog-jetshop-fi/'
  };

  const iframeSrc = iframes[culture] || iframes['sv-SE'];

  if (!category) {
    return (
      <CategoryWrapper>
        <CategoryHeaderLoadingState />;
      </CategoryWrapper>
    );
  }
  return (
    <CategoryWrapper>
      <TrackPageLoad
        pageType={'Beställ katalog'}
        route={category.primaryRoute}
      />
      <Container>
        <CategoryHeader
          category={category}
          parents={result?.data?.route?.parents}
        />
        <IframeContainer>
          <iframe frameBorder="0" src={iframeSrc}></iframe>
          <IframeSpinner />
        </IframeContainer>
      </Container>
    </CategoryWrapper>
  );
};

export default Category;
