import { useTracker } from '@jetshop/core/analytics/Analytics';
import { trackCartCheckoutEvent } from '@jetshop/core/analytics/tracking';
import CartProvider from '@jetshop/core/components/Query/CartProvider';
import t from '@jetshop/intl';
import { Above } from '@jetshop/ui/Breakpoints';
import Drawer, { DrawerTarget } from '@jetshop/ui/Modal/Drawer';
import { FlyoutTarget } from '@jetshop/ui/Modal/Flyout';
import { Price } from '@jetshop/ui/Price';
import { theme } from '../Theme';
import React from 'react';
import { styled } from 'linaria/react';
import { baseStyles } from '../ui/Button';
import CartItem from './CartItem';
import cartQuery from './CartQuery.gql';
import FreeShipping from './FreeShipping';
import { useChannelSettings } from '@jetshop/core/hooks/Channels/useChannelSettings';
import { Currency } from '@jetshop/ui/Price/Currency';
import RemoveConfigFromCart from './RemoveConfigFromCart';
import { ReactComponent as Klarna } from '../../svg/Klarna.svg';
import { ReactComponent as Mastercard } from '../../svg/Mastercard.svg';
import { ReactComponent as Postnord } from '../../svg/Postnord.svg';
import { ReactComponent as Visa } from '../../svg/Visa.svg';
import { ReactComponent as Trash } from '../../svg/Trash.svg';
import { Link } from 'react-router-dom';
import { RemoveConfigurationClickTracker } from '../DataLayer/DataLayer';
import PriceSection, { SinglePrice } from '../ProductPage/PriceSection';

const Flyout = styled('div')`
  background: white;
  color: ${theme.colors.black};
  ${theme.above.sm} {
    position: absolute;
    z-index: 999;
    right: 0;
    top: 5.5rem;
    width: 22rem;
    min-width: 19rem;
    max-width: 100%;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  }
`;

const LightText = styled('span')`
  color: #828282;
  font-size: 0.875rem;
  font-weight: normal;
`;

const Header = styled('header')`
  text-align: center;
  h2 {
  }
  ${LightText} {
    display: block;
  }
  padding: 1rem;
  border-bottom: 1px solid #e8e8e8;
`;

const ItemCount = ({ count }) => (
  <LightText>
    {t(
      `{
        count, plural,
        =0 {}
        one {1 item}
        other {{count} items}
            }`,
      { count }
    )}
  </LightText>
);

const CartItems = styled('section')`
  padding: 1rem 1rem 0;
`;

const Summary = styled('section')`
  background: #fff;
  padding: 0 1rem 1rem;

  a {
    width: 100%;
    color: white;
    :hover {
      color: white;
    }
  }

  h2 {
    font-weight: bold;
    font-size: 1.2em;
  }

  .discounts {
    margin-bottom: 1em;
    h2 {
      margin-bottom: 1em;
    }
  }

  .cart-total {
    padding-top: 1em;
    border-top: 1px solid ${theme.colors.lightgrey};

    > * + * {
      margin-top: 0.5em;
    }

    .shipping,
    .total,
    .vat {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .total {
      font-size: 1.25rem;
      font-weight: 600;
      color: ${theme.colors.black};
    }
  }
`;

const Flex = styled('div')`
  display: flex;
  justify-content: space-between;
`;

const Checkout = styled.a`
  ${baseStyles};
  width: 100%;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  &&:hover {
    color: white;
    text-decoration: none;
  }
`;

const RemoveConfiguration = styled('div')`
  position: absolute;
  top: 10px;
  right: 10px;
  button {
    padding: 0;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    outline: none;
    border: none;
    background: #fff;
    margin-left: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  svg {
    height: 16px;
    width: 16px;
  }
`;

const ConfigurationContainer = styled('div')`
  border: 1px solid #000;
  padding: 1rem 0.75rem 0.25rem;
  position: relative;

  + .config-container {
    margin-top: 10px;
  }

  h2 {
    font-size: 20px;
    padding: 0 0 5px 0;

    + .config-item {
    }
  }

  .config-item {
    border-top: 1px solid ${theme.colors.lightgrey};
    padding: 0;
    margin: 0px;

    > section {
      align-items: center;
      justify-content: space-between;
      padding: 0.5rem 0;
      display: flex;
      justify-content: space-between;
    }

    .item-details {
      padding-right: 0.5rem;
    }

    .image-wrapper {
      display: none;
    }

    .config-id,
    .config-part {
      display: none;
    }

    .price-outer {
      margin-top: 0;
      position: static;
      white-space: nowrap;
    }
    .article-number {
      display: none;
    }

    .price {
      font-size: 14px;
    }
  }
`;

const ChangeConfiguration = ({ collectionId, modal }) => {
  const configStrings = JSON.parse(localStorage.getItem('configStrings'));
  const configString = configStrings
    ? configStrings[collectionId]?.configString
    : null;
  const configUrl = configString
    ? '/config-redirect?config=' + configString
    : null;

  return (
    <>
      {configString ? (
        <ChangeConfigurationContainer>
          <Link to={configUrl} onClick={modal.hideTarget}>
            {t('Change this configuration')}
          </Link>
        </ChangeConfigurationContainer>
      ) : null}
    </>
  );
};

const ChangeConfigurationContainer = styled('div')`
  padding: 0 0 10px;
  font-size: 12px;

  a {
    color: ${theme.colors.black};
    text-decoration: underline !important;
  }
`;

const PaymentIcons = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  bottom: 0;
  width: 100%;
  margin: 1rem 0;
`;

const StyledVisa = styled(Visa)`
  margin-right: 1em;
`;

const createConfigItemCollections = cartItems => {
  let configList = {};
  let regularItemList = [];

  cartItems.forEach(cartItem => {
    const comments = cartItem.customerComments;
    const commentQty = comments.length;

    const isConfigurationItem =
      comments.length &&
      comments[commentQty - 1].name.match(/onfigura|kokoonpan/i);

    // PUSH CONFIG ITEMS INTO SEPARATE COLLECTIONS
    if (isConfigurationItem) {
      const configId = cartItem.customerComments[commentQty - 1].value;

      if (typeof configList[configId] !== 'undefined') {
        configList[configId].push(cartItem);
      } else {
        configList[configId] = [cartItem];
      }
    } else {
      regularItemList.push(cartItem);
    }
  });

  return {
    configurations: configList,
    regularItems: regularItemList
  };
};

const CartFlyoutView = ({ result, modal, ...rest }) => {
  const items = result?.data?.cart?.items ?? [];
  const itemCount = result?.data?.cart?.totalQuantity ?? 0;
  const checkoutUrl = result?.data?.cart?.externalCheckoutUrl;
  const discounts = result?.data?.cart?.aggregatedDiscounts ?? [];
  const track = useTracker();
  const { pricesIncVat } = useChannelSettings();
  const itemCollection = createConfigItemCollections(items);

  if (items.length === 0) {
    return (
      <Flyout {...rest}>
        <div style={{ padding: '2em' }}>{t('No items in cart.')}</div>
      </Flyout>
    );
  }

  return (
    <Flyout {...rest}>
      <Header>
        <h2>{t('Your cart')}</h2>
        <ItemCount count={itemCount} />
      </Header>
      <Summary>
        {checkoutUrl && (
          <Checkout
            data-testid="checkout-button"
            href={checkoutUrl}
            onClick={() => {
              track(trackCartCheckoutEvent({ cart: result.data.cart }));
            }}
          >
            {t('Check out')}
          </Checkout>
        )}
      </Summary>
      <CartItems>
        {Object.keys(itemCollection.configurations).map(collectionId => {
          const collectionHeader =
            itemCollection.configurations[collectionId][0].product.name;
          let collectionProdIds = [];
          itemCollection.configurations[collectionId].forEach(
            (configItem, index) => {
              collectionProdIds.push(configItem.id);
            }
          );

          return (
            <ConfigurationContainer
              className={'config-container'}
              key={collectionId}
            >
              <h2>{collectionHeader}</h2>
              <RemoveConfiguration>
                <RemoveConfigFromCart
                  cartQuery={cartQuery}
                  collectionId={collectionId}
                >
                  {removeMultipleFromCart => (
                    <RemoveConfigurationClickTracker
                      items={itemCollection.configurations[collectionId]}
                    >
                      <button
                        onClick={() =>
                          removeMultipleFromCart(collectionProdIds)
                        }
                      >
                        <Trash />
                      </button>
                    </RemoveConfigurationClickTracker>
                  )}
                </RemoveConfigFromCart>
              </RemoveConfiguration>

              <ChangeConfiguration collectionId={collectionId} modal={modal} />

              {itemCollection.configurations[collectionId].map(configItem => (
                <CartItem
                  item={configItem}
                  key={configItem.id}
                  isConfigItem={true}
                />
              ))}
            </ConfigurationContainer>
          );
        })}

        {itemCollection.regularItems.map(item => (
          <CartItem item={item} key={item.id} isConfigItem={false} />
        ))}
      </CartItems>
      <Summary>
        {discounts.length > 0 && (
          <div className="discounts">
            <h2>{t('Cart total')}</h2>
            <Flex>
              <label>{t('Order value')}</label>
              {/* <Price price={result.data.cart.productPreviousTotal} /> */}
              <PriceSection price={result.data.cart.productPreviousTotal} />
            </Flex>
            <h2 style={{ marginTop: '1em' }}>{t('Discounts')}</h2>
            {discounts.map(discount => {
              return (
                <Flex key={discount.name} style={{ marginTop: '1em' }}>
                  <label>{discount.name}</label>
                  {/* <Price price={discount.value} style={{ display: 'inline' }} /> */}
                  <PriceSection price={discount.value} />
                </Flex>
              );
            })}
          </div>
        )}

        <div className="cart-total">
          <FreeShipping
            className="shipping"
            cartTotal={result.data.cart.productTotal}
          />

          <div className="total">
            <label>
              {pricesIncVat ? t('Total incl. VAT') : t('Total excl. VAT')}
            </label>
            {/* <Price price={result.data.cart.productTotal} /> */}
            <PriceSection price={result.data.cart.productTotal} />
          </div>

          <VAT
            className="vat"
            total={result.data.cart.productTotal}
            pricesIncVat={pricesIncVat}
          />
        </div>
      </Summary>
      <PaymentIcons>
        <StyledVisa />
        <Mastercard />
        <Postnord />
        <Klarna />
      </PaymentIcons>
    </Flyout>
  );
};

function VAT({ total, pricesIncVat, ...rest }) {
  return (
    <div {...rest}>
      <label>{pricesIncVat ? t('of which VAT') : t('+ VAT')}</label>
      <div>
        {/* <Currency value={total.vat} /> */}
        <SinglePrice price={total.vat} />
      </div>
    </div>
  );
}

const CartFlyout = props => (
  // Wrap the flyout with the needed providers
  <CartProvider query={cartQuery}>
    {result =>
      result.data && result.data.cart ? (
        <Above breakpoint="md">
          {matches =>
            matches ? (
              <FlyoutTarget id="cart-flyout">
                {flyout => (
                  <CartFlyoutView modal={flyout} result={result} {...props} />
                )}
              </FlyoutTarget>
            ) : (
              <DrawerTarget id="cart-drawer">
                {drawer => (
                  <Drawer isOpen={drawer.isOpen} right>
                    <CartFlyoutView modal={drawer} result={result} {...props} />
                  </Drawer>
                )}
              </DrawerTarget>
            )
          }
        </Above>
      ) : null
    }
  </CartProvider>
);

export default CartFlyout;
