import React, { useState, useEffect } from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../Theme';

const ShowroomButton = styled('button')`
  background-color: rgba(110, 173, 42, 0.7);
  color: #ffffff;
  font-family: Proxima Nova;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 18px;
  border-radius: 100px;
  padding: 1rem 2rem;
  position: relative;
  z-index: 1;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: rgba(110, 173, 42, 0.9);
    transition: all 0.5s;
    border-radius: 10rem;
    z-index: -1;
  }

  &:hover:not([disabled]) {
    color: #ffffff;
    &:before {
      width: 100%;
    }
  }

  &:disabled,
  button[disabled] {
    color: white;
    background: rgba(110, 173, 42, 0.5);
    opacity: 1;
    border: 0;
  }
`;

const ShowroomBuyButton = ({ text, ...props }) => {
  return <ShowroomButton {...props}>{text}</ShowroomButton>;
};

export default ShowroomBuyButton;
