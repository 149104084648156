import CartProvider from '@jetshop/core/components/Query/CartProvider';
import { Above } from '@jetshop/ui/Breakpoints';
import { DrawerTrigger } from '@jetshop/ui/Modal/Drawer/DrawerTrigger';
import { FlyoutTrigger } from '@jetshop/ui/Modal/Flyout';
import { styled } from 'linaria/react';
import { css } from 'linaria';
import React, { Fragment } from 'react';
import { ReactComponent as Cart } from '../../svg/Cart.svg';
import Badge from '../ui/Badge';
import cartQuery from './CartQuery.gql';
import { theme } from '../Theme';

const Button = styled('button')`
  padding: 0 5px 4px;
  background: transparent;
  color: inherit;
  border: 0;
  outline: none;
  color: #fff;

  ${theme.below.md} {
    padding: 6px 5px 4px;

    svg {
      width: 20px;
      height: 18px;
    }
  }
`;

const cartButtonWrapper = css`
  position: relative;
  .badge {
    position: absolute;
    right: -5px;
    top: -5px;
    pointer-events: none;
  }
`;

function CartButton({ target, itemsInCart, className }) {
  return (
    <div className={cartButtonWrapper}>
      <Button
        className={className}
        data-testid="cart-button"
        onClick={target.isOpen ? target.hideTarget : target.showTarget}
      >
        <Cart className="badge-svg-wrapper" />
        {/*<span>{t('Cart')}</span>*/}
      </Button>
      {itemsInCart > 0 && <Badge text={itemsInCart} />}
    </div>
  );
}

function CartButtonFlyout({ className }) {
  return (
    <Fragment>
      <CartProvider query={cartQuery}>
        {result => {
          // Set items in cart to the API result.
          // If the result is undefined, fall back to 0
          const itemsInCart = result?.data?.cart?.totalQuantity || 0;

          return (
            // Use a flyout for breakpoints above md
            // Else use a Drawer
            <Above breakpoint="md">
              {matches =>
                matches ? (
                  <FlyoutTrigger id="cart-flyout">
                    {flyout => (
                      <CartButton
                        className={className}
                        target={flyout}
                        itemsInCart={itemsInCart}
                      />
                    )}
                  </FlyoutTrigger>
                ) : (
                  <DrawerTrigger preventOverflow={true} id="cart-drawer">
                    {drawer => (
                      <CartButton
                        className={className}
                        target={drawer}
                        itemsInCart={itemsInCart}
                      />
                    )}
                  </DrawerTrigger>
                )
              }
            </Above>
          );
        }}
      </CartProvider>
    </Fragment>
  );
}

export default CartButtonFlyout;
