import React from 'react';
import { styled } from 'linaria/react';

import { ReactComponent as InstagramLogo } from '../../../svg/Instagram.svg';
import { ReactComponent as FacebookLogo } from '../../../svg/Facebook.svg';
import { ReactComponent as PinterestLogo } from '../../../svg/Pinterest.svg';

import { theme } from '../../Theme';

const Wrapper = styled('div')`
  margin-right: 0;
  padding: 3.75rem 0 0;

  ${theme.below.lg} {
    padding: 2rem 0 0;
  }

  ${theme.below.md} {
    padding: 2rem 0 0;
  }

  ${theme.below.sm} {
    padding: 1.25rem 0 1.5rem;
  }

  ul {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 3rem;

    ${theme.above.md} {
    }
  }

  li {
    margin: 0 1.25rem 0 0;
  }

  a.social-links {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  color: ${theme.colors.black};
`;

const LogoWrapper = styled('span')`
  background: ${theme.colors.black};
  width: 44px;
  height: 44px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  ${theme.below.md} {
    width: 34px;
    height: 34px;
    margin-right: 0.25rem;

    svg {
      width: 34px;
      height: 34px;
    }
  }
  svg {
    width: 44px;
    height: 44px;
  }
`;

const SocialLinks = ({ showLabel = true }) => (
  <Wrapper>
    <ul>
      <li>
        <a
          className="social-links"
          aria-label="Facebook"
          href="https://www.facebook.com/fjaraskupan/"
          target="_blank"
        >
          <LogoWrapper>
            <FacebookLogo />
          </LogoWrapper>
          {showLabel && <label>Facebook</label>}
        </a>
      </li>
      <li>
        <a
          className="social-links"
          aria-label="Instagram"
          href="https://www.pinterest.se/fjaraskupan/"
          target="_blank"
        >
          <LogoWrapper>
            <PinterestLogo />
          </LogoWrapper>
          {showLabel && <label>Pinterest</label>}
        </a>
      </li>
      <li>
        <a
          className="social-links"
          aria-label="Instagram"
          href="https://www.instagram.com/fjaraskupan/"
          target="_blank"
        >
          <LogoWrapper>
            <InstagramLogo />
          </LogoWrapper>
          {showLabel && <label>Instagram</label>}
        </a>
      </li>
    </ul>
  </Wrapper>
);

export default SocialLinks;
