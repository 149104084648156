import React from 'react';
import { styled } from 'linaria/react';
import { cx } from 'linaria';
import { theme } from '../../Theme';

const List = styled('ul')`
  margin-left: 8px;
  margin-right: 0px;
  justify-content: flex-end;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0;
  display: flex;
  align-items: center;
  z-index: 999;

  ${theme.above.md} {
    width: 200px;
    margin: 0;
  }

  ${theme.below.md} {
    width: 80px;
  }


  &.left {
    margin-right: 8px;
    margin-left: 0px;
    justify-content: flex-start;
    
    li.top-nav-list-item:first-of-type {
      padding-left: 0px;
      //padding-right: 8px;
    }
  }

  
  li.top-nav-list-item {
    list-style: none;
    color: ${theme.colors.white};
    display: block;
    padding: 0 11px;
    white-space: nowrap;

    &.top-nav-list-item:last-of-type {
      //padding-left: 8px;
      padding-right: 0px;
    }
    
    &:empty {
      display: none;
    }
    
    a {
      
      span {
        display: inline-block;
        border-bottom: 1px solid transparent;
        padding: 5px 0 4px;
        font-weight: ${theme.fontWeights.medium};
        font-size: 1rem;
        color: ${theme.colors.white};
      }
      
      &:hover span {
        border-color: ${theme.colors.white};
      }
      
    }
    
    svg {
      //stroke: ${theme.colors.white};
    }


    ${theme.below.md}{
      padding: 0 2px;
    }
    
    
  }
  button {
    background: none;
    color: #666;
  }

  ${theme.below.md} {
    
  }
`;

export default ({ children, searchOpen, left, ...rest }) => (
  <List
    className={cx(left ? 'left' : null, searchOpen && 'searchOpen')}
    {...rest}
  >
    {React.Children.map(
      children,
      item => item && <li className="top-nav-list-item">{item}</li>
    )}
  </List>
);
