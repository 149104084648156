import React from 'react';
import { Link } from 'react-router-dom';
import getContentValue from "../getContentValue";

const UspItem = ({ link, text }) => {
  link = getContentValue(link);
  text = getContentValue(text);
  return text !== 'null' ? (
    <li>
      {link ? <Link to={link}>{text}</Link> : <span>{text}</span>}
    </li>
  ) : null;
};

export default UspItem;
