import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../Theme';

const ArticleContainer = styled('article')`
  > *:first-child,
  > .html-container > *:first-child {
    margin-top: 0;
  }

  h1 {
    width: 100%;
    padding: 0;
    font-size: 40px;
    line-height: 1.3;
    margin: 1.5rem 0 1rem;

    ${theme.below.sm} {
      font-size: 34px;
      margin: 1.5rem 0 0.25rem;
    }

    + p {
      margin-top: 0;
    }
  }

  h2 {
    width: 100%;
    padding: 0;
    font-size: 26px;
    line-height: 1.3;
    margin: 1.5rem 0 0.5rem;

    ${theme.below.sm} {
      margin: 1.5rem 0 0.25rem;
    }

    + p {
      margin-top: 0;
    }
  }

  h3 {
    width: 100%;
    padding: 0;
    font-size: 20px;
    line-height: 1.3;
    margin: 1.5rem 0 0.5rem;

    ${theme.below.sm} {
      margin: 1.5rem 0 0.25rem;
    }

    + p {
      margin-top: 0;
    }
  }

  h4 {
    ${theme.below.sm} {
    }
  }

  p {
    font-size: 1rem;
    line-height: 1.6;
    margin: 1.25rem 0 1.25rem;

    ${theme.below.sm} {
      font-size: 14px;
      margin: 0.25rem 0 1.25rem;
    }
  }

  ul,
  ol {
    ${theme.below.sm} {
    }

    li {
    }
  }

  a {
    color: ${theme.colors.black};
  }

  img {
    max-width: 100%;
  }
`;

export default ArticleContainer;
