import React, { useContext } from 'react';
import useAuth from '@jetshop/core/components/AuthContext/useAuth';
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';
import t from '@jetshop/intl';
import { Above } from '@jetshop/ui/Breakpoints';
import { DrawerTrigger } from '@jetshop/ui/Modal/Drawer/DrawerTrigger';
import { styled } from 'linaria/react';
import { useQuery } from 'react-apollo';
import { Link } from 'react-router-dom';
import { ReactComponent as Menu } from '../../../svg/Menu.svg';
import CartButton from '../../Cart/CartButton';
import CartFlyout from '../../Cart/CartFlyout';
import { theme } from '../../Theme';
import { CategoryMenu } from './CategoryMenu/CategoryMenu';
import homeCategoriesQuery from './HomeCategoriesQuery.gql';
import { Logo } from './Logo';
import MobileMenu from './MobileMenu';
import SearchButton from './SearchButton';
import TopNav from './TopNav';
import { Notifications } from '../Notifications';
import OuterMaxWidth from '../OuterMaxWidth';
import { SearchBar } from './SearchBar';
import { ToastContainer } from 'react-toastify';
import ChannelBanner from './RecommendedChannel/ChannelBanner';
import TopBar from './TopBar';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import { ReactComponent as Person } from '../../../svg/Person.svg';
import ChannelSelector from './ChannelSelector/ChannelSelector';

const MyPagesLink = styled(Link)`
  display: flex;
  align-items: center;
  padding: 0 2px 2px;

  svg {
    margin: 2px 4px 0;
    height: 24px;
  }
`;

//
// const StyledLink = styled(Link)`
//   text-decoration: none;
//
//   :hover {
//     text-decoration: none;
//   }
// `;

const Container = styled('header')`
  position: relative;

  a {
    text-decoration: none;

    &:hover {
      //text-decoration: underline;
      //color: #000;
    }
  }

  ${theme.below.md} {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .header-button {
    text-decoration: none;
    color: ${theme.colors.white};

    &:hover {
      text-decoration: underline;
      //color: #000;
    }
  }

  .header-button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    flex-direction: column;
    cursor: pointer;

    &:hover {
      svg {
        use {
          fill: white;
        }
      }
    }

    svg {
      //width: 23px;
      //height: 23px;
      margin: 0;

      use {
        fill: #fff;
      }
    }

    span {
      font-size: 0.75rem;
    }

    .badge-svg-wrapper {
      height: 30px;
      position: relative;

      .badge {
        position: absolute;
        top: -5px;
        right: -5px;
        color: white;
        font-size: 0.5rem;
      }
    }
  }
`;

const HeaderContainer = styled('div')`
  //height: 88px;
  position: relative;
  z-index: 99;
  width: 100%;

  ${theme.below.md} {
    height: auto;
  }

  > div {
    width: 100%;
    display: flex;
    align-items: center;
    background: transparent;
    transition: background-color 0.3s;
  }

  &.opaque > div {
    background-color: #1f1f1f;

    .sub-menu-wrapper {
      opacity: 1;
    }
  }

  .bg-element {
    transition: 0s background-color;
  }

  .bg-element:hover {
    background-color: #1f1f1f;
    transition: background-color 0.1s ease-in;
  }

  .bg-element:not(:hover) {
    background-color: transparent;
    transition: background-color 0.8s ease-in;
  }

  &.transparent {
    + .bg-toning {
      display: block;
      position: absolute;
      top: 0;
      /* top: 48px; */
      right: 0;
      left: 0;
      background-image: linear-gradient(
        to bottom,
        rgba(31, 31, 31, 1) 0%,
        rgba(31, 31, 31, 0) 100%
      );
      height: 260px;

      ${theme.below.md} {
        top: 0;
      }

      //z-index: -1;

      /* &.b2b {
        top: 0;
      } */
    }
    &:hover {
      .sub-menu-wrapper {
        opacity: 1;
      }
    }
  }

  .retailers-wrapper {
    display: none;
    @media screen and (min-width: 1169px) {
      display: block;
    }
  }
`;

const HeaderItemsContainer = styled('div')`
  display: flex;
  width: 100%;
  height: 100%;
  //position: relative;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: 4rem; // FOR LOGIN PAGE

  ${theme.below.lg} {
    height: 74px;
  }
`;

export const MenuButton = styled('button')`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  background: transparent;
  align-items: center;
  color: ${theme.colors.white};
  padding: 9px;

  svg {
    height: 21px;
    width: 25px;
  }

  :focus,
  :active,
  :hover {
    outline: none;
    //opacity: 0.8;
  }

  label {
    margin-top: 3px;
    display: none;
  }
`;

const RetailerLink = styled(Link)`
  font-weight: 600;
`;

// export const IconContainer = styled('span')`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   height: 20px;
//   margin: 0;
// `;

const ChannelSelectorWrapper = styled.div`
  /* position: absolute; */
  top: 8px;
  right: 0;
  background: ${theme.colors.black};
  border-radius: 50rem;
  transition: 0.1s;

  &:hover {
    background: #666;
  }

  > button {
    padding: 0.25rem 0.5rem 0.25rem 0.75rem;

    svg {
      fill: #fff;
    }
  }

  div:first-of-type {
    top: 61px;
  }
`;

function TopHeader({ result, headerIsOpaque, isB2b }) {
  const [searchOpen, setSearchOpen] = React.useState(false);
  const { loggedIn } = useAuth();
  const isLoggedOutB2b = !loggedIn && isB2b;
  const { routes } = useShopConfig();

  return (
    <>
      <HeaderContainer className={headerIsOpaque ? 'opaque' : 'transparent'}>
        <div
          className="bg-element"
          style={{ backgroundColor: headerIsOpaque && '#1f1f1f' }}
        >
          <OuterMaxWidth>
            <HeaderItemsContainer>
              <Above breakpoint="lg">
                {matches =>
                  matches ? (
                    <>
                      <Logo />
                      {!isLoggedOutB2b && <CategoryMenu data={result.data} />}
                    </>
                  ) : (
                    <>
                      {!isLoggedOutB2b && (
                        <TopNav left>
                          <DrawerTrigger
                            preventOverflow={true}
                            id="menu-drawer"
                          >
                            {drawer => (
                              <MenuButton
                                onClick={
                                  drawer.isOpen
                                    ? drawer.hideTarget
                                    : drawer.showTarget
                                }
                              >
                                <div className="header-button">
                                  <Menu />
                                </div>
                                <label>{t('Menu')}</label>
                              </MenuButton>
                            )}
                          </DrawerTrigger>
                        </TopNav>
                      )}
                      <Logo />
                    </>
                  )
                }
              </Above>

              <TopNav
                style={{ position: 'relative' }}
                searchOpen={searchOpen}
                right
              >
                {!isLoggedOutB2b && (
                  <div className="retailers-wrapper">
                    <RetailerLink to={'/aterforsaljare'}>
                      <span>{t('retailers')}</span>
                    </RetailerLink>
                  </div>
                )}
                {!isLoggedOutB2b && isB2b && (
                  <MyPagesLink
                    className="header-button"
                    to={routes.myPages.path}
                  >
                    <Person />
                    {/*<span>{loggedIn ? t('My Pages') : t('Log in')}</span>*/}
                  </MyPagesLink>
                )}
                {!isLoggedOutB2b && <CartButton className="header-button" />}
                {!isLoggedOutB2b && (
                  <SearchButton
                    className="header-button"
                    searchOpen={searchOpen}
                    setSearchOpen={setSearchOpen}
                    key="searchButtonPosed"
                  />
                )}
                {!isB2b && (
                  <Above breakpoint="lg">
                    <ChannelSelectorWrapper>
                      <ChannelSelector />
                    </ChannelSelectorWrapper>
                  </Above>
                )}
              </TopNav>

              <CartFlyout />
            </HeaderItemsContainer>
            <SearchBar searchOpen={searchOpen} setSearchOpen={setSearchOpen} />
          </OuterMaxWidth>
        </div>
      </HeaderContainer>
      {/* <div className={isB2b ? 'bg-toning b2b' : 'bg-toning'} /> */}
      <div className={'bg-toning'} />
    </>
  );
}

export default function Header({ headerIsOpaque, setHeaderAsOpaque }) {
  const result = useQuery(homeCategoriesQuery, {
    variables: {
      levels: 1
    }
  });

  const { selectedChannel } = useContext(ChannelContext);
  const isB2b = selectedChannel.name === 'B2B';

  return (
    <>
      <Container>
        {/* <Above breakpoint="md">
          {matches => matches && !isB2b && <TopBar />}
        </Above> */}

        <TopHeader
          isB2b={isB2b}
          result={result}
          headerIsOpaque={headerIsOpaque}
          setHeaderAsOpaque={setHeaderAsOpaque}
        />
        {!isB2b && <ChannelBanner />}
        <Above breakpoint="lg">
          {matches => (matches ? <></> : <MobileMenu data={result.data} />)}
        </Above>
        <Notifications />
        <ToastContainer />
      </Container>
    </>
  );
}
