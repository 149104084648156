// import Price from '@jetshop/ui/Price/Price';
// import ChannelContext from '@jetshop/core/components/ChannelContext';
// import { theme } from '../../../src/components/Theme';
// import {styled} from 'linaria/react';
// import React, { useContext } from 'react';
// import { css, cx } from 'linaria';

// const PriceSumStyle = styled.div`
//   margin: 0 !important;
//   text-align: right;

//   .price {
//     font-size: 26px !important;
//     padding-left: 5px;
//     ${theme.below.lg} {
//       font-size: 22px !important;
//     }
//   }
// `;

// const PriceSection = ({price, previousPrice}) => {
//   const { selectedChannel } = useContext(ChannelContext);
//   console.log("price in pricesection", price)
//   console.log("selectedChannel.id", selectedChannel?.id)
//   console.log("selectedChannel.culture", selectedChannel)
//   const formattedPrice = formatPrice(price?.incVat, selectedChannel?.id);

//   if (!selectedChannel?.id) {
//     return <div style={{color: "lime"}}>No price channel</div>;
//   }

//   return (
//     <PriceSumStyle>
//     {selectedChannel?.id == "3" ?
//     (
//       <Price price={price} previousPrice={previousPrice} />
//     ) :
//     (
//       <span className="price">{formattedPrice}ff</span>
//     )
//     }
//     </PriceSumStyle>)
// }

// export default PriceSection;

// function isDecimal(number) {
//   return !Number.isInteger(number)
// }

// export function formatPrice(price, channelId ) {
//   if (!price || price == null) {
//      return ''
//   }

//   const isDecimalPrice = isDecimal(price)

//   if (isDecimalPrice) {
//     console.log("isDEcimal")
//      price = price.toFixed(2)
//      console.log("price dec fixed", price)
//   }

//   if (price >= 1000) {
//      price = price.toString().replace('.', ',')
//      // insert space every 3 digits before the comma
//      price = price.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
//   } else {
//      price = price.toString().replace('.', ',')
//   }

//   if (channelId == 1) {
//      return price + ' KR'
//   } else if (channelId == 2) {
//      return price + ' NKR'
//   } else if (channelId == 3) {
//      return price + ' €'
//   } else if (channelId == 5) {
//     return price + ' DKR'
//   } else {
//      return price
//   }
// }

import ChannelContext from '@jetshop/core/components/ChannelContext';
import { theme } from '../../../src/components/Theme';
import { styled } from 'linaria/react';
import React, { useContext } from 'react';

const PriceWrapper = styled.div`
  display: flex;
  column-gap: 0.5rem;
  margin: 0 !important;
  flex-wrap: wrap;
  align-items: baseline;

  .sale-price {
    color: #eb0000;
    white-space: nowrap;
  }

  .sale-price-old {
    color: #767676;
    text-decoration: line-through;
    white-space: nowrap;
  }
`;

const PriceSection = ({ price, previousPrice, className }) => {
  // price = {incVat: 122222.001}
  const { selectedChannel } = useContext(ChannelContext);
  const formattedPrice = formatPrice(price?.incVat, selectedChannel?.id);
  const formattedPreviousPrice = formatPrice(
    previousPrice?.incVat,
    selectedChannel?.id
  );

  // If previousPrice?.incVat is not defined, then it is not on sale
  // or if price is less than previousPrice, then it is on sale
  const isOnSale = price?.incVat < previousPrice?.incVat;

  // if (!selectedChannel?.id) {
  //   return <></>;
  // }

  return (
    <PriceWrapper id="price-section-wrapper" className={className}>
      <>
        <span className={`price ${isOnSale && 'sale-price'}`}>
          {formattedPrice}
        </span>

        {isOnSale && (
          <span className={`price ${isOnSale && 'sale-price-old'}`}>
            {formattedPreviousPrice}
          </span>
        )}
      </>
    </PriceWrapper>
  );
};

export default PriceSection;

export const SinglePrice = ({ price, className }) => {
  const { selectedChannel } = useContext(ChannelContext);
  const formattedPrice = formatPrice(price, selectedChannel?.id);

  // if (!selectedChannel?.id) {
  //   return <></>;
  // }

  return (
    <PriceWrapper id="price-currency-section-wrapper" className={className}>
      <span className="currrency">{formattedPrice}</span>
    </PriceWrapper>
  );
};

// function isDecimal(number) {
//   return !Number.isInteger(number);
// }

function isDecimal(value) {
  return typeof value === 'number' && !Number.isNaN(value);
}

export function formatPrice(price, channelId) {
  const isEuro = channelId == 3;
  if (price === undefined || price === false || price === null) {
    return '';
  }

  // Specifically handle the case where price is 0
  if (price === 0) {
    if (isEuro) {
      price = '0,00';
    } else {
      price = '0';
    }
  } else {
    const isDecimalPrice = isDecimal(price);
    if (isDecimalPrice && isEuro) {
      // Format decimal prices with two decimal places
      price = price.toFixed(2);
    } else {
      // For non-decimal, non-channelId 3 scenarios, round to nearest whole number
      price = price.toFixed(0);
    }

    if (price >= 1000) {
      price = price.toString().replace('.', ',');
      // insert space every 3 digits before the comma
      price = price.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
    } else {
      price = price.toString().replace('.', ',');
    }
  }

  if (channelId == 1) {
    return price + ' kr';
  } else if (channelId == 2) {
    return price + ' NKR';
  } else if (isEuro) {
    return price + ' €';
  } else if (channelId == 5) {
    return price + ' DKR';
  } else {
    return price;
  }
}
