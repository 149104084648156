import React from 'react';
import { Link } from 'react-router-dom';
import t from '@jetshop/intl';
import getContentValue from "../getContentValue";

const HeadingLinkItem = ({ link, text }) => {
  link = getContentValue(link);
  text = getContentValue(text);
  return (
    <li>
      <Link to={link}>
        <h4>{text}</h4>
        <span>{t('Read more')} ></span>
      </Link>
    </li>
  );
};

export default HeadingLinkItem;
