import { default as Breadcrumbs } from '@jetshop/ui/Breadcrumbs';
import Image from '@jetshop/ui/Image/Image';
import React, { useContext, useEffect } from 'react';
import { styled } from 'linaria/react';
import { css } from 'linaria';
import { theme } from '../Theme';
import { TransparencyContext } from '../Shop';

export const CategoryHeaderWrapper = styled('div')`
  color: ${theme.colors.black};
    // ${theme.below.xl} {
  //   max-width: 100%;
  //   width: 100%;
  //   padding: 0px;
  // }
`;

const CategoryImageWrapper = styled('div')`
  width: 100%;
  margin-top: -88px;
  position: relative;

  ${theme.below.lg} {
    margin-top: -74px;
  }


  img {
    z-index: -1;
  }

  ::after {
    display: block;
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(31, 31, 31, 0.4);
  }

    //  ${theme.below.sm} {
  //    min-height: 458px;
  //   ::after {
  //     margin-top: -217px;
  //     height: 217px;
  //   }
  // }
`;

export const NoImageWrapper = styled('div')`
  margin-top: 0rem;
  padding: 0px;
`;

const CategoryName = styled('h1')`
  font-size: 40px;
  margin-bottom: 16px;

  ${theme.below.sm} {
    font-size: 34px;
  }
`;

const CategoryContent = styled('div')`
  max-width: 540px;
  font-weight: 400;
  line-height: 26px;
  font-size: 16px;

  ${theme.below.sm} {
    font-size: 14px;
    line-height: 24px;
  }
`;

const StyledBreadcrumbs = styled(Breadcrumbs)`
  ul& {
    margin-bottom: 32px;

    ${theme.below.md} {
      display: none;
    }
  }

  ul& > li,
  > li a {
    font-size: 1rem;
    color: ${theme.colors.black};
  }

  > li a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  > li:last-child::after {
    display: none;
  }
`;

const headerImageStyles = css`
  color: white;

  ${StyledBreadcrumbs} {
    a,
    li {
      color: white;
    }
  }
`;

export const Inner = styled('div')`
  max-width: 90rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  padding: 2.5rem 2rem 0rem;

  ${theme.below.sm} {
    padding: 1.5rem 1.25rem;
  }

  &.on-image {
    min-height: 582px;
    padding: 8rem 2rem 3rem;
    justify-content: space-between;

    ${theme.below.md} {
      justify-content: flex-end;
    }

    ${theme.below.sm} {
      min-height: 458px;
      padding: 6rem 1rem 2rem;
    }

    h1 {
      font-size: 70px;
      max-width: 500px;

      ${theme.below.sm} {
        font-size: 50px;
      }
    }
  }
`;

const Content = ({ category, breadcrumbProps, isImageBackground }) => (
  <Inner className={isImageBackground ? 'on-image' : 'no-image'}>
    <StyledBreadcrumbs {...breadcrumbProps} />
    <div>
      <CategoryName data-testid="page-header">{category.mainHeader ? category.mainHeader : category.name}</CategoryName>
      <CategoryContent
        data-testid="category-description"
        dangerouslySetInnerHTML={{
          __html: category.content
        }}
      />

    </div>
  </Inner>
);

const CategoryHeader = ({ category, parents }) => {
  const breadcrumbProps = {
    breadcrumbText: category.breadcrumbText,
    parents
  };
    const transparencyContext = useContext(TransparencyContext);
  const isImageBackground = category.images && category.images.length > 0;

  useEffect(() => {
    if (isImageBackground && transparencyContext.headerIsOpaque) {
      transparencyContext.setHeaderAsOpaque(false);
    } else if (!isImageBackground && !transparencyContext.headerIsOpaque) {
      transparencyContext.setHeaderAsOpaque(true);
    }
  });

  // console.log(category, " - category");
  return (
    <CategoryHeaderWrapper>
      {isImageBackground ? (
        <CategoryImageWrapper>
          <Image
            src={category.images}
            fillAvailableSpace
            cover
            quality={80}
            className={headerImageStyles}
            critical={true}
          >
            <Content
              category={category}
              breadcrumbProps={breadcrumbProps}
              isImageBackground={isImageBackground}
            />
          </Image>
        </CategoryImageWrapper>
      ) : (
        <NoImageWrapper>
          <Content
            category={category}
            breadcrumbProps={breadcrumbProps}
            isImageBackground={isImageBackground}
          />
        </NoImageWrapper>
      )}
    </CategoryHeaderWrapper>
  );
};

export default CategoryHeader;
