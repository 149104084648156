import React from 'react';
import { styled } from 'linaria/react';
import getContentValue from "../getContentValue";

const Heading = styled('h2')``;

const SingleH3Heading = ({ text }) => {
  text = getContentValue(text);

  return <Heading>{text}</Heading>;
};

export default SingleH3Heading;
