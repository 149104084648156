import React, { useContext } from 'react';
import loadable from '@loadable/component';
import LoadingPage from '../LoadingPage';
import { TransparencyContext } from '../Shop';
import BlogMainPage from './Blog/BlogMainPage';
import { theme } from '../Theme';
import SystemsMainPage from './Systems/SystemsMainPage';
import DesignYourselfPage from './DesignYourselfPage';
import ResellerPage from './ResellerPage';
import OrderCatalogPage from './OrderCatalogPage';

export const LoadableSystemArticlePage = loadable(
  () => import('./Systems/SystemArticlePage'),
  {
    fallback: <LoadingPage />
  }
);

export const LoadableBlogArticlePage = loadable(
  () => import('./Blog/BlogArticlePage'),
  {
    fallback: <LoadingPage />
  }
);

export const LoadableStandardCategoryPage = loadable(
  () => import('./StandardCategoryPage'),
  {
    fallback: <LoadingPage />
  }
);

export const LoadableWindowedCategoryPage = loadable(
  () => import('./WindowedCategoryPage'),
  {
    fallback: <LoadingPage />
  }
);

const CategoryPage = props => {
  // const location = useLocation();
  // const { search } = location;
  const blogCategoryId = theme.settings.blogCategoryId;
  const faqCategoryId = theme.settings.faqCategoryId;
  const systemsCategoryId = theme.settings.systemsCategoryId;
  const designYourselfCategoryId = theme.settings.designYourselfCategoryId;
  const resellerCategoryId = theme.settings.resellerCategoryId;
  const orderCatalogCategoryId = theme.settings.orderCatalogCategoryId;
  const transparencyContext = useContext(TransparencyContext);

  // const searchObject = qs.parse(search, { ignoreQueryPrefix: true });
  // const standardPagination = searchObject.standardPagination === 'true';
  const standardPagination = true;

  // VENTILATION SYSTEMS MAIN & ARTICLE PAGES
  if (props.category?.id === systemsCategoryId) {
    return (
      <SystemsMainPage {...props} transparencyContext={transparencyContext} />
    );
  } else if (props.category?.parent?.id === systemsCategoryId) {
    return (
      <LoadableSystemArticlePage
        {...props}
        transparencyContext={transparencyContext}
      />
    );
  }

  // BLOG MAIN & BLOG ARTICLE PAGES
  if (
    props.category?.id === blogCategoryId ||
    props.category?.id === faqCategoryId
  ) {
    return (
      <BlogMainPage {...props} transparencyContext={transparencyContext} />
    );
  } else if (
    props.category?.parent?.id === blogCategoryId ||
    props.category?.parent?.id === faqCategoryId
  ) {
    return (
      <LoadableBlogArticlePage
        {...props}
        transparencyContext={transparencyContext}
      />
    );
  }

  // DESIGN YOURSELF PAGE
  if (props.category?.id === designYourselfCategoryId) {
    return (
      <DesignYourselfPage
        {...props}
        transparencyContext={transparencyContext}
      />
    );
  }

  // RESELLER PAGE
  if (props.category?.id === resellerCategoryId) {
    return <ResellerPage {...props} />;
  }

  // ORDER CATALOG PAGE
  if (props.category?.id === orderCatalogCategoryId) {
    return <OrderCatalogPage {...props} />;
  }

  if (standardPagination) {
    return <LoadableStandardCategoryPage {...props} />;
  } else {
    return <LoadableWindowedCategoryPage {...props} />;
  }
};

export default CategoryPage;
