import t from '@jetshop/intl';
import Image, { transparentDataImg } from '@jetshop/ui/Image/Image';
import { Price } from '@jetshop/ui/Price';
import ProductLink from '@jetshop/ui/ProductLink';
import Badges from '@jetshop/ui/ProductList/Badges';
import { css, cx } from 'linaria';
import React from 'react';
import { theme } from '../Theme';
import { ProductGridClickTracker } from '../DataLayer/DataLayer';
import PriceSection from '../ProductPage/PriceSection';

const wrapperStyling = css`
  font-size: 1rem;

  a {
    text-decoration: none;
    color: inherit;
    display: block;
    background: white;
    margin: 0 0.5rem 1rem;
    position: relative;
      //border: 1px solid ${theme.colors.tablegrey};
  }

  .product-card-detail {
    background: ${theme.colors.background};
    padding: 0 1rem 0.75rem;
    line-height: 1.35;
    //position: absolute;
    bottom: 0;
    left: 0;
    right: 0px;
    text-align: center;

    header {
      margin: 0;
    }

    h3 {
      font-size: 24px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      padding-top: 0.75rem;
      line-height: 1.2;

    }

    h4 {
      font-weight: normal;
      font-size: 1em;
      color: ${theme.colors.darkerGrey};
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .package-badge {
    background: black;
    color: white;
    font-size: 14px;
    padding: 3px 6px;
  }

  .price-package-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-family: ${theme.fonts.secondary};
    text-align: center;

    .package-price-label {
      font-size: 12px;
      color: grey;
      margin-left: 5px;
    }
  }

  .badge-top-right {
    top: 3em;
  }

  .buy-now {
    padding: 1rem 0;
    text-align: center;
    font-size: 1rem;

    ${theme.below.sm} {
      font-size: 14px;
    }
  }
`;

export function ProductCard({
  product,
  className,
  imageAspect = '1:1',
  imageSizes = [1 / 4, 1 / 3, 1 / 2, 1 / 2],
  forwardRef,
  as = 'li',
  children,
  list,
  ...linkProps
}) {
  const hasImages = product.images && product.images.length > 0;
  const Tag = as;
  let badges = [...product.badges];
  product.isPackage &&
    badges.push({
      location: 'TOP_RIGHT',
      name: 'Package',
      text: t('Package'),
      style: 'package-badge'
    });

  return (
    <Tag
      className={cx('product-card', wrapperStyling, className)}
      data-testid="product"
      data-product-card
      ref={forwardRef}
    >
      <ProductLink product={product} {...linkProps}>
        <ProductGridClickTracker product={product} categoryName={list}>
          {hasImages ? (
            <Image
              sizes={imageSizes}
              aspect={imageAspect}
              alt={product.images[0].alt}
              src={product.images[0].url}
            >
              <Badges badges={badges} />
            </Image>
          ) : (
            <Image src={transparentDataImg} />
          )}
          <section className="product-card-detail">
            <header>
              <h3>{product.name}</h3>
              {/*<h4 className="sub-name">{product.subName || '\u00A0'}</h4>*/}
            </header>
            <div className="price-package-wrapper">
              {/* <Price
                price={product.price}
                previousPrice={product.previousPrice}
              /> */}
              <PriceSection
                price={product.price}
                previousPrice={product.previousPrice}
              />
            </div>
          </section>
          {!!list && !!list.match(/related|system/i) && (
            <div className="buy-now">{t('Buy now')}</div>
          )}
        </ProductGridClickTracker>
      </ProductLink>
      {children}
    </Tag>
  );
}
