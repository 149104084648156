import React from 'react';
import { styled } from 'linaria/react';
import ContentContainer from '../Common/ContentContainer';

import { theme } from '../../Theme';
import getContentValue from "../getContentValue";

const Wrapper = styled('div')`
  background-color: ${theme.colors.background};
  margin: 4rem 0;
  clear: both;
`;
const Container = styled(ContentContainer)`
  padding-top: 6.25rem;
  padding-bottom: 3.75rem;
  max-width: 90rem;
  display: block;

  ul {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;

    li {
      width: 30%;
      padding: 2rem 0;
    }

    a {
      display: block;
      text-decoration: none;
      color: ${theme.colors.black};

      &:hover span {
        text-decoration: underline;
      }
    }

    h4 {
      font-size: 26px;
    }

    span {
      display: block;
      font-size: 16px;
      padding-top: 1.5rem;
    }

  }

  ${theme.below.lg} {
    ul {

      li {
        width: 45%;
      }
    }
  }


  ${theme.below.md} {
    ul {

      li {
        width: 100%;
      }
    }
  }


  ${theme.below.sm} {
    padding-top: 3.25rem;
    padding-bottom: 2.25rem;

    ul {

      li {
        padding: 1.5rem 0;
      }

      h4 {
        font-size: 22px;
      }

      span {
        font-size: 14px;
        padding-top: 1rem;
      }
    }
  }

}


`;

const Title = styled('h3')`
  width: 100%;
  padding: 0 0 2rem;
  font-size: 18px;

  ${theme.below.sm} {
    padding: 0 0 1rem;
    font-size: 16px;
  }
`;

const HeadingLinkList = ({ heading, children }) => {
  heading = getContentValue(heading);

  return (
    <Wrapper>
      <Container>
        {heading && <Title>{heading}</Title>}
        <ul>{children}</ul>
      </Container>
    </Wrapper>
  );
};

export default HeadingLinkList;
