import React from 'react';
import { styled } from 'linaria/react';
import { css } from 'linaria';
import Image from '@jetshop/ui/Image/Image';
import { TrendLink } from '../../ui/Button';
import ContentContainer from '../Common/ContentContainer';
import { theme } from '../../Theme';
import getContentValue from "../getContentValue";

const Wrapper = styled('div')`
  height: 930px;
  background-position: center center;
  background-size: cover;
  position: relative;

  ${theme.below.xl} {
  }
  ${theme.below.lg} {
    height: 715px;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(31, 31, 31, 0.4);
  }
`;
const Container = styled(ContentContainer)`
  flex-direction: column;
  justify-content: center;
  color: white;
  height: 100%;
  text-align: left;

  ${theme.below.lg} {
    justify-content: flex-end;
    padding-bottom: 3rem;
  }

  > * {
    max-width: 500px;
  }
`;
const Title = styled('h1')`
  font-size: 70px;
  margin-bottom: 18px;

  ${theme.below.md} {
    font-size: 50px;
    margin-bottom: 12px;
  }
`;

const Description = styled('p')`
  font-size: 16px;
  font-weight: ${theme.fontWeights.medium};
  margin-bottom: 30px;
  line-height: 1.45em;

  ${theme.below.md} {
    width: 100%;
    font-size: 14px;
  }
`;

const StyledButton = styled(TrendLink)`
  background: transparent;
  border: 1px solid ${theme.colors.white};
  min-width: 232px;

  ${theme.below.md} {
    min-width: 190px;
  }
`;

const heroStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BigHero = ({ headingText, bodyText, buttonText, buttonLink, image }) => {
  headingText = getContentValue(headingText);
  bodyText = getContentValue(bodyText);
  buttonText = getContentValue(buttonText);
  buttonLink = getContentValue(buttonLink);
  image = getContentValue(image);

  return (
    <Wrapper>
      <Image
        fillAvailableSpace={true}
        src={image}
        className={heroStyles}
        sizes={[1, 1, 1, 1]}
      >
        <Container>
          {headingText && <Title>{headingText}</Title>}
          {bodyText && <Description>{bodyText}</Description>}
          {buttonText && buttonLink && (
            <StyledButton to={buttonLink}>{buttonText}</StyledButton>
          )}
        </Container>
      </Image>
    </Wrapper>
  );
};

export default BigHero;
