import React from 'react';
import { styled } from 'linaria/react';
import Image from '@jetshop/ui/Image/Image';
import { TrendLink } from '../../ui/Button';

import { theme } from '../../Theme';
import getContentValue from "../getContentValue";

const ImageLinkItemWrapper = styled('div')`
  position: relative;

  ${theme.below.md} {
    + div {
    }
  }
  ${theme.below.md} {
  }
`;
const Container = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0 0.5rem;
`;

const StyledButton = styled(TrendLink)`
  min-width: 232px;
  color: ${theme.colors.white};

  ${theme.below.lg} {
    min-width: 0;
  }
`;

const ImageLinkItem = ({ image, link, buttonText }) => {
  image = getContentValue(image);
  link = getContentValue(link);
  buttonText = getContentValue(buttonText);

  return (
    <ImageLinkItemWrapper>
      <Image src={image} sizes={[1, 1, 1, 1]}></Image>
      <Container>
        {buttonText && link && (
          <StyledButton to={link} dangerouslySetInnerHTML={{__html: buttonText}}></StyledButton>
        )}
      </Container>
    </ImageLinkItemWrapper>
  );
};

export default ImageLinkItem;
