import ChannelContext from '@jetshop/core/components/ChannelContext';
import React, { useContext } from 'react';
import { Helmet } from 'react-helmet-async';

const Imbox = ({}) => {
  const { selectedChannel } = useContext(ChannelContext);

  return (
    selectedChannel.name === 'SE' && (
      <Helmet>
        <script type="text/javascript">
          {`                     
               var _sid = '2503';
               (function() {
               var se = document.createElement('script'); se.type = 'text/javascript'; se.async = true;
               se.src = ('https:' == document.location.protocol ? 'https://' : 'http://') + 'files.imbox.io/app/dist/initWidget.js';
               var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(se, s);
               })();
            `}
        </script>
      </Helmet>
    )
  );
};

export default Imbox;
